import {useState, useRef, useReducer, useEffect, useCallback, useLayoutEffect } from "react"
import useSessionStorageState from 'use-session-storage-state';
import { useLocation, useNavigate } from "react-router-dom";
import {REFRESH_URL, BASE_WS, LOGOUT_URL } from "./Costants";





export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
          const handleResize = () => {
              setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  return windowSize;
}






export const useWebSocket = () => {

	const [ received, setReceived  ] = useState(null)
	const [ wsState, setWsState ] = useState(0)
	const wsRef = useRef()


	const genWs = () => {
		const ws = new WebSocket( BASE_WS )
		ws.onmessage =  msg => {
			if ( msg.data != null ){
				const data = JSON.parse(msg.data) 
				setReceived( data )
			}
		}
		ws.onopen = () =>{
			setWsState( ws.readyState )
		}
		ws.onclose = () =>{
			setWsState( ws.readyState )
		}

		wsRef.current = ws 
	}


	useEffect( () => {
		if ( 
			wsState === WebSocket.CLOSING 
			|| wsState === WebSocket.CLOSED
		){
			refreshToken({}, true )
				.then( _ => {
					genWs()
				})
		}
	}, [wsState])



	useEffect( () => {
		genWs()

		return () => {
			if ( wsRef?.current != null ){
				wsRef.current.onclose = () =>{ }
				wsRef.current.close()
			}
			// clearInterval(interval)
		}
	}, [])


	return [ received, setReceived]

}




export const useAuthCheck = () => {


	const navigate = useNavigate()
	const location = useLocation()
	const [ user, ] = useSessionStorageState('user', { defaultValue: null })

	useEffect( () => {
		if ( user == null ) {
			navigate('/admin/login')
		}
		if ( user?.role !== 1 && user?.role !== 2 ){
			navigate('/admin/login')
		}
	}, [ user, navigate ])

}





const genHeaders = ( json ) => {
	const baseHeaders ={
		Accept:'application/json',
	}

	if ( json === true ){
		return new Headers({...baseHeaders, 'Content-Type':'application/json'})
	}
	else{
		return new Headers(baseHeaders)
	}
}





const refreshToken = async ( original_request, empty=false ) =>{

	const controller = new AbortController()
	const headers = genHeaders( false )

	const request  = new Request( REFRESH_URL, {
		method:'GET',
		signal:controller.signal,
		headers: headers,
		credentials:"include",
	})
	return await fetch( request )
		.then( resp => {
			if ( resp.ok ){

				if ( empty === false ){
					return fetch(original_request)
						.then( origina_resp => {
							return origina_resp
						})
				}
				else 
					return 
			}
			else{
				return resp
			}

		})                                                                                                        
		.catch( err => {                                                                                        
			console.log( 'error in fetch', err )                                                            
			return err
		})                                                                                                        


}





export const useLogout = () => {

	const navigate = useNavigate() 
	const [ ,setUser ] = useSessionStorageState('user')

	const doLogout = useCallback( () => {
		const headers = genHeaders()

		const request = new Request( LOGOUT_URL, 
			{
				headers:headers,
				method:'GET',
				credentials:'include'
			})

		fetch(request)
			.then( () => {
				setUser({})
				navigate('/admin/login')
			})
			.catch( () => {
				setUser({})
				navigate('/admin/login')
			})

	}, [ setUser ])



	return doLogout
}



const useRespHandler = ( ) => {

	const doLogout = useLogout()
	const respHandler = useCallback( async ( resp, dispatch, request    ) => {


	if ( resp.ok === true ){
		resp.json()
			.then( data => {
				dispatch({ type:'DATA', data:data })
			})
			.catch( err => {
				dispatch({ type:'ERROR', error:err})
			})
	}
	else{

		resp.json()
			.then( async err => {
				if ( err?.error?.includes('token expired')){
					await refreshToken( request )
						.then( refresh_resp => {

							if ( refresh_resp.ok ){
								refresh_resp.json()
									.then( data => {
										dispatch({ type:'DATA', data:data })

									})
									.catch( err =>{
										dispatch({ type:'ERROR', error:err})
									})
							}
							else{
								refresh_resp.json()
									.then( err => {
										dispatch({ type:'ERROR', error:err})
										doLogout()
									})
									.catch( err => {
										dispatch({ type:'ERROR', error:err})
										doLogout()
									})
							}
						})
						.catch( err =>{
							dispatch({ type:'ERROR', error:err})
							doLogout()
						})
				}
				else {
					dispatch({ type:'ERROR', error:err})
				}

			})
			.catch( err => {
				dispatch({ type:'ERROR', error:err})
			})
	}

		
	}, [doLogout])


	return respHandler
}




const fetchReducer = ( oldState, action ) => {
	switch ( action.type ){
		case 'DATA':
			return {
				data:action.data,
				loading:false,
				error:null,
			}
		case 'LOADING':
			return {
				data:null,
				loading:true,
				error:null,
			}
		case 'STOP_LOADING':
			return {
				...oldState,
				loading:false,
			}
		case 'ERROR':
			return {
				data:null,
				loading:null,
				error:action.error,
			}
		default:
			break
	}
}


export const useDelete = ({ url}) => {

	const [state, dispatch ] = useReducer( fetchReducer , { data:null, loading:false, error:null })
        const controller = useRef() 
        const startedRef = useRef(false)
        const urlRef = useRef('')


	const respHandler = useRespHandler()

        useEffect( () => {
                if( 
                        url != null
                        && startedRef.current === false
                        && (
                                urlRef.current !== url.href
                        )
                ) {

                        controller.current != null && controller.current.abort()
                        controller.current = new AbortController()
                        urlRef.current = url.href
                        startedRef.current = true
			dispatch({ type:'LOADING'})

			const headers = genHeaders( false )

                        const request  = new Request( url, {
                                method:'DELETE',
                                signal:controller.current.signal,
                                headers: headers,
				credentials:"include",
                        })
			const origin_request = request.clone()
                        fetch( request )
                                .then( resp => {
					respHandler( resp, dispatch, origin_request )
                                })                                                                                                        
                                .catch( err => {                                                                                        
                                        console.log( 'error in fetch', err )                                                            
					dispatch({ type:'ERROR', error:err})
                                })                                                                                                        
                }                                                                                                                         
                else{                                                                                                                     
                        urlRef.current = ''                                                                                               
                }                                                                                                                         
                                                                                
        }, [  
		url,                                                           
		respHandler
        ])

        useEffect( () => {
                if ( state?.data != null || state?.error != null ){
			dispatch({ type:'STOP_LOADING'})
                        startedRef.current = false
                }
        }, [
                state?.data,
                state?.error,
        ] )

        useEffect( () => {
                return () => {
                        controller.current &&
                                controller.current.abort()
                }
        }, [] )

	return state
}






export const useGet = ({ url}) => {

	const [state, dispatch ] = useReducer( fetchReducer , { data:null, loading:false, error:null })
        const controller = useRef() 
        const startedRef = useRef(false)
        const urlRef = useRef('')

	const respHandler = useRespHandler()

        useEffect( () => {
                if( 
                        url != null
                        && startedRef.current === false
                        && (
                                urlRef.current !== url.href
                        )
                ) {

                        controller.current != null && controller.current.abort()
                        controller.current = new AbortController()
                        urlRef.current = url.href
                        startedRef.current = true
			dispatch({ type:'LOADING'})

			const headers = genHeaders( false )

                        const request  = new Request( url, {
                                method:'GET',
                                signal:controller.current.signal,
                                headers: headers,
				credentials:"include",
                        })
			const origin_request = request.clone()
                        fetch( request )
                                .then( resp => {
					respHandler( resp, dispatch, origin_request )
                                })                                                                                                        
                                .catch( err => {                                                                                        
                                        console.log( 'error in fetch', err )                                                            
					dispatch({ type:'ERROR', error:err})
                                })                                                                                                        
                }                                                                                                                         
                else{                                                                                                                     
                        urlRef.current = ''                                                                                               
                }                                                                                                                         
                                                                                
        }, [  
		url,                                                           
		respHandler
        ])

        useEffect( () => {
                if ( state?.data != null || state?.error != null ){
			dispatch({ type:'STOP_LOADING'})
                        startedRef.current = false
                }
        }, [
                state?.data,
                state?.error,
        ] )

        useEffect( () => {
                return () => {
                        controller.current &&
                                controller.current.abort()
                }
        }, [] )

	return state
}





export const usePost = ({ url, query, json=true  }) => {

	const [state, dispatch ] = useReducer( fetchReducer , { data:null, loading:false, error:null })
        const controller = useRef() 
        const startedRef = useRef(false)
        const urlRef = useRef('')
        const queryRef = useRef('')
	const respHandler  = useRespHandler()

        useEffect( () => {
                if( 
                        url != null
                        && query != null
                        && startedRef.current === false
                        && (
                                urlRef.current !== url.href
                                || queryRef.current !== JSON.stringify( query )
                        )
                ) {

                        controller.current != null && controller.current.abort()
                        controller.current = new AbortController()
                        urlRef.current = url.href
                        startedRef.current = true
			dispatch({ type:'LOADING'})

			if ( json === true )
                        	queryRef.current = JSON.stringify( query )
			else
				queryRef.current = query

			const headers = genHeaders( json )

                        const request  = new Request( url, {
                                method:'POST',
                                body:queryRef.current,
                                signal:controller.current.signal,
                                headers: headers,
				credentials:"include",
                        })
			const origin_request = request.clone()
                        fetch( request )
                                .then( resp => {
					respHandler( resp, dispatch, origin_request )
                                })                                                                                                        
                                .catch( err => {                                                                                        
                                        console.log( 'error in fetch', err )                                                            
					dispatch({ type:'ERROR', error:err})
                                })                                                                                                        
                }                                                                                                                         
                else{                                                                                                                     
                        urlRef.current = ''                                                                                               
                        queryRef.current = ''                                                                                             
                }                                                                                                                         
                                                                                
        }, [  
		json,
		url,                                                           
                query,                                                         
		respHandler
        ])


        useEffect( () => {
                if ( state?.data != null || state?.error != null ){
			dispatch({ type:'STOP_LOADING'})
                        startedRef.current = false
                }
        }, [
                state?.data,
                state?.error,
        ] )

        useEffect( () => {
                return () => {
                        controller.current &&
                                controller.current.abort()
                }
        }, [] )

	return state
}



                                                                                                                                         







export const useForm = ( initialFormData ) =>{


	const [ formValues, setFormValues ] = useState( initialFormData )


	const setFormValuesByEvent = useCallback( ( event ) => {
		const value = event.target.value
		const name = event.target.name
		setFormValues( old => {
			return {
				...old,
				[name]:value
			}
		})
	}, [])

	const setFormValuesByName = useCallback( ( name, value ) => {

		setFormValues( old => {
			return {
				...old,
				[name]:value
			}
		})
	}, [])

	return [
		formValues,
		setFormValues,
		setFormValuesByEvent,
		setFormValuesByName,
	]

}
