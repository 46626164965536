import NavButton from "../views/button/NavButton"
import RiepilogoOrdine from "../views/pagamento/RiepilogoOrdine"
import '../views/pagamento/Pagamento.css'
import TipoPagamento from "../views/pagamento/TipoPagamento"
import {useContext, useEffect, useState} from "react"
import {OrderContext} from "../context/OrderContext"
import Button from "../views/button/Button"
import { usePost } from '../Hooks'
import {ORDINI_URL} from "../Costants"
import ConfermaOrdineModal from "../views/pagamento/ConfermaOrdineModal"
import {useNavigate} from "react-router-dom"
import ErrorOrdineModal from "../views/pagamento/ErrorOrdineModal"
import {toast} from "react-hot-toast"
import Loader from '../views/loader/Loader'







const PaymentPage = () => {


	const [ ordine, setOrdine ] = useContext(OrderContext)
	const [ showModal, setShowModal ] = useState( false )
	const [ showErrorModal, setShowErrorModal ] = useState( false )

	const navigate = useNavigate()

	const [ postData, setPostData ] = useState({url:null, query:null})
	const pState = usePost( postData )

	const [ errors, setErrors ] = useState(null)

	const setNotaByEvent = (event)  => {
		setOrdine( old => {
			return {
				...old,
				note:event.target.value
			}
		})
	}



	const sendOrdine = () => {

		if ( ordine?.params?.is_active === true ){
			if ( 
				ordine?.pagamento !== 'contanti' && ordine?.pagamento !== 'pos'
			){
				setErrors("Tipo di pagamento non indicato, per piacere scegli il metodo con cui desideri pagare. Grazie")
				setShowErrorModal(true)
				return
			}
			const body = {
				articoli: ordine.articoli.map( art => { return { id:art.id, quantita:art.quantita }  }),
				pagamento:ordine.pagamento,
				note:ordine.note.replace( "\n", " "),
				db:ordine.params.db,
				pista:ordine.params.pista
			} 
			setPostData({ url:ORDINI_URL, query:body})
		}
		else{
			toast.error("Siamo chiusi")
		}
	}


	const endAction = () =>{
		setOrdine( old => {
			return {
				...old,
				articoli:[],
				totale:0,
				pagamento:null,
				note:"",
			}
		})
		setShowModal(false)
		navigate( `/menu/${ordine.params.slug}`)
	}


	useEffect(() => {
		if ( pState?.data?.status === 'ok'){
			setShowModal(true)
		}
		else if ( pState?.error?.status === 'fail'){
			setErrors( pState?.error?.error )
			setShowErrorModal( true )
		}

		setPostData({ url:null, query:null })

	}, [
		pState?.data?.status,
		pState?.error?.status,
	])


	return (
		<div className='page payment'>

			{
				showErrorModal === true &&
					<ErrorOrdineModal
						closeModal={ () => setShowErrorModal(false) }
						errors={errors}
					/>
			}
			{
				showModal === true &&
					<ConfermaOrdineModal
						closeModal={ () => endAction() }
					/>
			}

			<NavButton top={true}/>
			<RiepilogoOrdine/>
			<TipoPagamento/>

			<section>
				<h3>NOTE</h3>
				<textarea
					maxLength={31}
					placeholder="Note per la cucina"
					value={ ordine.note }
					onChange={ event => setNotaByEvent( event) }
					
				/>
			</section>

			{
				pState.loading === true ?
					<Loader/>:
					<Button
						text="CONFERMA"
						onClick={ () => sendOrdine() }
					/>
			}
		</div>
	)
}


export default PaymentPage
