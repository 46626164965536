import Card from "../wrapper/Card"
import { IoTrashOutline } from 'react-icons/io5'
import { CiEdit } from 'react-icons/ci'
import Switch from "react-switch";
import {useOutletContext} from "react-router-dom";
import {useState} from "react";



const MenuArticolo = ({ articolo }) => {

	const [{ menuModalDispatch, setDisponibile}, ] = useOutletContext()


	return ( 
		<Card
			style={{
				padding:'13px'
			}}
		>
			<div 
				className='menu-art'
				onClick={ event => {
					event.stopPropagation()
				}}
			>

				<div>
					<p>
					{articolo.descrizione}
					</p>
					<p
						style={{fontSize:'.9rem'}}
					>
					{Number( articolo.prezzo).toFixed(2)}€
					</p>

				</div>



				<div>
					<i
						onClick={()=>{
							menuModalDispatch({type:'EDIT_ARTICOLO', articolo:articolo })
						}}
					>
						<CiEdit/>
					</i>
					
					<i
						onClick={ ()=> menuModalDispatch({type:'DELETE_ITEM', item:articolo }) }
					>
						<IoTrashOutline/>
					</i>

					<Switch
						onChange={ () => {
						
							setDisponibile( articolo.id )
						}}
						value={ articolo.is_disponibile }
						checked={ articolo.is_disponibile  }
						id={'is_disponibile'}
						name='is_disponibile'
					/>
				</div>
			</div>
		</Card>
	)
}


export default MenuArticolo
