import Button from "../button/Button"
import Modal from "../wrapper/Modal"
import '../wrapper/Wrappers.css'

import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FaTimesCircle } from 'react-icons/fa'

const ErrorOrdineModal = ({closeModal, errors}) => {


	return (
		<Modal
			showHadler={ closeModal }
		>
			<div 
				className='dialog error-dialog'
				style={{ paddingTop:'20px'}}
			>


				<i>
				<div/>
				<FaTimesCircle/>
				</i>


				<h2>Qualcosa è andato storto...</h2>

				<p>Siamo spiacenti ma si è verificato un errore.
				<br/>
				<br/>
				<br/>
					<details>
						{errors.toString()}	
					</details>
				<br/>
				</p>

				<Button
					text='Ok'
					onClick={ closeModal }
				/>
			</div>
		</Modal>

	)
}


export default ErrorOrdineModal 
