import {Children, createContext, useCallback, useEffect, useState} from "react";
import useSessionStorageState from "use-session-storage-state";





const initialValues = {
	articoli:[],
	totale:0,
	pagamento:null,
	note:"",
	params:{},
}



export const OrderContext = createContext( initialValues )



export const OrderContextProvider = ({ children }) => {


	const [ ordine, setOrdine ] = useSessionStorageState( 'ordine', { defaultValue: initialValues})

	const addArticolo = useCallback( ( articolo ) => {
		setOrdine( old => {

			const oldArt = old.articoli
			let newArt = []
			if ( oldArt.length > 0 ){
				let added = false
				newArt = old.articoli.map( ordArt => {
					if ( ordArt.id === articolo.id ){
						ordArt.quantita += 1
						added = true
					}
					return ordArt
				})

				if ( added === false ){
					newArt.push({
						id:articolo.id,
						prezzo:articolo.prezzo,
						quantita:1,
						descrizione:articolo.descrizione,
					})
				}
			}
			else{
				newArt = [{
						id:articolo.id,
						prezzo:articolo.prezzo,
						quantita:1,
						descrizione:articolo.descrizione,
				}]

			}

			return {
				...old,
				articoli:[
					...newArt,
					]
			}
		})
	}, [setOrdine ])


	const delArticolo = useCallback( (articolo) => {
		setOrdine( old => {
			let removeArt = false
			let newArt = old.articoli.map( ordArt => {
				if ( ordArt.id === articolo.id ){
					ordArt.quantita -= 1
					if ( ordArt.quantita === 0)
						removeArt = true
				}
				return ordArt
			})
			if ( removeArt === true ){
				newArt = newArt.filter( ordArt => ordArt.id !== articolo.id )	
			}
			return {
				...old,
				articoli:[ ...newArt ]
			}
		})
	}, [setOrdine])


	useEffect( () => {
		const tot = ordine.articoli.reduce( ( accum, ordArt ) =>  accum + ( Number( ordArt.prezzo ) * Number(ordArt.quantita) ), 0 )
		setOrdine( old => {
			return {
				...old,
				totale:tot,
			}
		})
	}, [ ordine.articoli, setOrdine ])


	return (
		<OrderContext.Provider value={[ ordine, setOrdine, addArticolo, delArticolo ]}>{ children }</OrderContext.Provider>
	)

}
