import Modal from "../wrapper/Modal"
import Button from '../button/Button'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import { useEffect,  useState} from "react"
import { useDelete, } from '../../Hooks'
import { GET_USERS_URL } from "../../Costants"
import {useOutletContext} from "react-router-dom"







const DeleteAccountModal = ({ showHadler, item,  }) => {


	const [ ,setMenuContext ] = useOutletContext()
	const [ error, setError ] = useState(null)

	const [ delData, setDelData ] = useState({url:null})
	const dState = useDelete(delData)


	const deleteItem = () => {
		const url = new URL( GET_USERS_URL )
		const params = new URLSearchParams({ id:item.id })
		url.search = params.toString()
		setDelData({url:url})
	}


	useEffect( () =>{
		if ( dState?.data?.status === 'ok' ) {
			setMenuContext( old => {
				let newAccounts = [ ...old.accounts ] 
				newAccounts = newAccounts.filter( (acc) => acc.id !== dState.data.data )
				return {
					...old,
					accounts:newAccounts
				}
			})
			showHadler()
		}
		else if ( dState?.error != null ){
			setError( dState?.error?.error?.join('\n') )
		}
	}, [
		dState?.data?.status,
		dState?.error,
		setMenuContext, 
		showHadler,
	])


	return (
		<Modal
			showHadler={ showHadler }
		>
			<div className="articolo-form">

			<h1
				style={{ 'color':'var(--senape)'}}
			>
				Elimina account
			</h1>


			<p>
				Vuoi davvero eliminare quest account  
			</p>

			{
				error != null &&
					<label style={{color:'red'}}>{error}</label>
			}

			<div
				className='btn-group'
			>

				<Button
					text="Conferma"
					icon={( <FaCheck/> )}
					color='green'
					onClick={ () =>  deleteItem() }
				/>

				<Button
					text='Annulla'
					color='red'
					onClick={ showHadler }
					icon={( <FaTimes/> )}
				/>

			</div>
			</div>
		</Modal>
	)

}


export default DeleteAccountModal
