import {useCallback, useEffect, useMemo, useState} from "react"
import useSessionStorageState from "use-session-storage-state"
import {DATABASE_URL} from "../../Costants"
import {useGet, usePost} from "../../Hooks"
import Button from "../button/Button"
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai'



const ActivateButton = ({ db }) => {


	const [ user, setUser ] = useSessionStorageState('user', )

	const isActive  = useMemo( () =>{
		return user?.db?.find( ( {id,} ) => id === Number( db ?? 0 ) )?.is_active ?? false 
	}, [db, user?.db ] )

	const desc = user?.db?.find( ( {id,} ) => id === Number( db ) )?.descrizione
	const text = `La ${desc} è ${ isActive === true ? "aperta" : "chiusa" }`
	const icon = isActive === true ?
		<AiOutlineCheck/>:
		<AiOutlineClose/>




	const [ postData, setPostData ] = useState( { url:null, query:null})
	const pState = usePost( postData )

	const [ getData, setGetData ] = useState({url:null})
	const gState = useGet( getData )



	const activeteHanler = useCallback(()=>{
		const body = {
			db:Number(db),
			activate:!isActive
		}
		setPostData({url:DATABASE_URL, query:body})
	}, [db, isActive])



	useEffect( () => {
		const  url = new URL( `${db}/`, DATABASE_URL )
		setGetData({ url:url})
	},[ db ])



	useEffect( () => {
		if ( gState?.data?.status === 'ok'){
			setUser( old => {
				const newDb = old.db.map( db => {
					if (db.id === db ){
						db.is_active = gState?.data?.data?.is_active
					}

					return db
				})
				return {
					...old,
					db:newDb
				}
			})
		}
		setGetData({url:null})
	}, [ gState?.data?.status ])


	useEffect( () => {
		if ( pState?.data?.status === 'ok'){
			setUser( old => {
				const newDb = [ ...old.db].map( db_obj => {

					if ( db_obj.id === Number(db) ){
						db_obj.is_active = pState?.data?.data?.is_active
					}
					return db_obj
				})
				return {
					...old,
					db:newDb
				}
			})
		}
		setPostData({url:null, query:null})
	}, [ pState?.data?.status ])







	return ( 


		<Button
			text={ text }
			icon={ icon }
			color={ isActive === true ? 'gree' : 'red'}
			onClick={ () => activeteHanler() }
		/>
	)
}



export default ActivateButton
