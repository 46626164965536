import './Pages.css'
import headerImg from "../static/header.jpg"; 
import logoKing from "../static/logo-king.png"; 
import { BsInfoCircle } from 'react-icons/bs'
import AllergeniModal from '../views/wrapper/AllergeniModal';
import {useCallback, useContext, useEffect, useState} from 'react';
import MenuList from '../views/menu/MenuList';
import Button from '../views/button/Button';
import NavButton from '../views/button/NavButton';
import {useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {OrderContext} from '../context/OrderContext';



const MenuPage = () => {


	const { params }= useParams()
	const [ orderContext, setOrderContext ] = useContext(OrderContext)

	// DEV
	const [ acceptOrders, setAcceptOrders ] = useState(true)


	const navigate = useNavigate()

	const [ showModal, setShowModal ] = useState(false)

	const modalHandler = useCallback(()=> {
		setShowModal( old => !old )
	}, [setShowModal])




	const location = useLocation()

	useEffect(() => {
		const string = window.atob(params)
		const parsedParams  = string.split("&").map( n => Number(n) )
		if ( ! parsedParams.every( n => !isNaN(n) ) ){
			navigate('/not-found')
		}
		const [ db, pista ] = parsedParams
		setOrderContext( old => {
			return {
				...old,
				params:{
					db:db,
					pista:pista,
					slug:params
				}
			}
		})
	}, [params, location.pathname ])


	return (
		<>
		<div className='page menu'>
			{
				showModal === true &&
					<AllergeniModal
						closeModal={ modalHandler }
					/>
			}


			<div className='header-over'/>
			<header 
				style={{
					'backgroundImage':`url(${headerImg})`
				}}
			>
			</header>

			<main>
				<section className='brand'>

					<div>
						<h1>
							Bowling King 
						</h1>
						<img 
							src={`${logoKing}`}
							alt='logo'
						/>	
					</div>



					<div
						onClick={ modalHandler }
					>
						Allergeni
						<i><BsInfoCircle/></i>
					</div>

				</section>


				<MenuList/>

			</main>
		</div>

			<div>
				{
					acceptOrders === true ?
						<NavButton/>:
						<div
							style={{
								width:'100%',
								height:'var( --nav-btn-height )',
								backgroundColor:'var( --green )',
								color:'white',
								display:'flex',
								justifyContent:'center',
								alignItems:'center',
								position:'fixed',
								bottom:'0',
								padding:'0 23px',
							}}
						>
							<h3>AL MOMENTO NON ACCETTIAMO ORDINI</h3>

						</div>

				}
			</div>
		</>
	)
}


export default MenuPage
