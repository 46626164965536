import {useCallback, useContext, useMemo} from "react"
import {OrderContext} from "../../context/OrderContext"
import IconRadio from "../radio/IconRadio"





const TipoPagamento= () => {

	const [ ordine, setOrdine ] = useContext(OrderContext)

	const setPagamentoByEvent = useCallback((event) => {
		const value = event.target.value
		setOrdine( old => {
			return {
				...old,
				pagamento:value
			}
		})
	}, [ setOrdine ])


	return ( 

		<section className='tipo-pagamento'>


			<h3>TIPO  DI PAGAMENTO</h3>


			<IconRadio
				setValueByEvent={event => setPagamentoByEvent(event)}
				selected={ ordine.pagamento }
			/>


		</section>
	)
}

export default TipoPagamento 
