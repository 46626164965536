import {useContext, useMemo} from "react"
import {OrderContext} from "../../context/OrderContext"
import { IoTrashOutline } from 'react-icons/io5'



const ArticoloItem = ({ articolo }) => {


	const [ orderArticoli, , addArticolo, delArticolo ] = useContext(OrderContext)




	const delIcon = () => {
		const qt = orderArticoli.articoli.find( ordArt => ordArt.id === articolo.id )?.quantita ?? 0
		if ( qt != 0 ){
			return (
				<i
					onClick={ event => {
						event.stopPropagation()
						delArticolo(articolo)
					}}
				>
					<IoTrashOutline/>
				</i>
			)
		}
		else return null
	}



	const renderQt = () =>{
		const qt = orderArticoli.articoli.find( ordArt => ordArt.id === articolo.id )?.quantita ?? 0
		if ( qt != 0 ){
			return (
				<div className='articolo-item-qt'>
					<label>x{qt}</label>
				</div>
			)
		}
		else return null
	}




	return (
		<li 
			className='articolo-item'
			onClick={ () => addArticolo( articolo ) }
		>

			{ renderQt() }

			<div className='articolo-item-text'>
				<p>
				{ articolo?.descrizione }
				</p>

				<span>

				<label>
					{ Number( articolo?.prezzo).toFixed(2) } €
				</label>
					<label>
					  { articolo.extra }

					</label>	
				</span>
			</div>
			{ delIcon() }
		</li>
	)
}


export default ArticoloItem






