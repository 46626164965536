import {useMemo, useRef} from "react"
import {useOutletContext} from "react-router-dom"
import Card from "../wrapper/Card"
import OrdersItem from "./OrderItem"
import './Orders.css'


const MENU = [

	{
		id:1,
		tavolo:'Pista 3',
		data:'23/05/2023 22:20',
		pagamento:'pos',
		totale:153.4,
		note:"non ci volgio i peli di cazzo dentro dio cane!",
		articoli:[
			{
				id:1,
				nome:'1-litro',
				prezzo:7.50,
				quantita:4
			},
			{
				id:2,
				nome:'media',
				prezzo:2.50,
				quantita:6
			},
			{
				id:3,
				nome:'mezzo-litro',
				prezzo:3.50,
				quantita:2
			},
			{
				id:1,
				nome:'1-litro',
				prezzo:7.50,
				quantita:4
			},
			{
				id:2,
				nome:'media',
				prezzo:2.50,
				quantita:6
			},
			{
				id:3,
				nome:'mezzo-litro',
				prezzo:3.50,
				quantita:2
			},
		]

	},
	{
		id:3,
		tavolo:'Pista 1',
		data:'23/05/2023 13:34',
		pagamento:'contanti',
		totale:534.4,
		note:"",
		articoli:[
			{
				id:1,
				nome:'1-litro',
				prezzo:7.50,
				quantita:4
			},
			{
				id:2,
				nome:'media',
				prezzo:2.50,
				quantita:6
			},
			{
				id:3,
				nome:'mezzo-litro',
				prezzo:3.50,
				quantita:2
			},
			{
				id:4,
				nome:'piccola',
				prezzo:1,
				quantita:2
			},
		]

	},
	{
		id:5,
		tavolo:'Pista 4',
		data:'23/05/2023 23:34',
		pagamento:'contanti',
		totale:53.4,
		note:"",
		articoli:[
			{
				id:1,
				nome:'1-litro',
				prezzo:7.50,
				quantita:4
			},
			{
				id:2,
				nome:'media',
				prezzo:2.50,
				quantita:6
			},
			{
				id:3,
				nome:'mezzo-litro',
				prezzo:3.50,
				quantita:2
			},
			{
				id:4,
				nome:'piccola',
				prezzo:1,
				quantita:2
			},
		]

	},
]




const OrdersList  = ({ ordersDispatch }) => {

	const [ {ordini,}, ] = useOutletContext()




	const menu = useMemo(  () => {
		if ( ordini == null || ordini.length < 1 ) return 

		return ordini.map(( order , idx) => {
			return ( 
				<OrdersItem 
					order={order}
					key={idx}
					ordersDispatch={ action => ordersDispatch(action) }
				/> 
			)
		})
	}, [ordini?.length])


	return ( 
		<Card>
		<section
			className='order-list'
		>

			<ul>
				{ menu }
				
			</ul>

		</section>
		</Card>
	)
}


export default OrdersList
