import Modal from "../wrapper/Modal"
import Button from '../button/Button'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import {useCallback, useEffect, useRef, useState} from "react"
import { useForm, usePost } from '../../Hooks'
import TextInput from "../input/TextInput"
import {CREATE_CATEGORY_URL, CREATE_USER_URL, EDIT_CATEGORY_URL} from "../../Costants"
import Switch from "react-switch";
import {useOutletContext} from "react-router-dom"




const CreateCategoryModal = ({ showHadler, db, categoria }) => {


	const formRef = useRef()
	const [ postData, setPostData ] = useState({ url:null, query:null})
	const pState  =  usePost( postData )


	const [ ,setMenuContext ] = useOutletContext()

	const [ error, setError ] = useState(null)


	const [
		formValues,
		setFormValues,
		setFormValueByEvent,
		setFormValuesByName,
	] = useForm( categoria != null ? categoria :{
		nome:"",
		is_disponibile:true
	}) 

	const sendCategoria = () => {
		let url = null
		const formData = new FormData(formRef.current)

		if ( categoria == null ){
			url = new URL( `${db}/`, CREATE_CATEGORY_URL )
		}
		else{
			url = new URL( `${db}/`, EDIT_CATEGORY_URL )
			formData.append('id', categoria.id )
		}

		setPostData({
			url:url,
			query:formData,
			json:false
		})
	}


	useEffect( () =>{
		if ( pState?.data?.status === 'ok' ) {

			setMenuContext( old => {
				let newMenu = [ ...old.menu_data ]

				if ( categoria != null ){
					newMenu = newMenu.map(([cat, articoli]) =>  {
						if ( cat.id === pState.data.data.id ){
							return [ pState.data.data, articoli]
						}
						else
							return [ cat, articoli ]
					})
				}else {
					newMenu.push([ pState.data.data, [] ])
				}

				return {
					...old,
					menu_data:newMenu
				}
			})

			showHadler()
		}
		else if ( pState?.error != null ){
			setError( pState?.error?.error?.join('\n')  )
		}
	}, [
		pState?.data?.status,
		pState?.error
	])


	return (

		<Modal
			showHadler={ showHadler }
		>
			<form 
				className='user-form'
				ref={formRef}
			>

				<h1
					style={{ 'color':'var(--senape)'}}
				>
					{
						categoria == null ?
							"Nuova categora":
							"Modifica categoria"
					}
				</h1>

				<TextInput
					label='Nome'
					value={ formValues.nome }
					setValueByEvent={ event => setFormValueByEvent(event) }
					name='nome'

				/>

				<input
					type='file'
					name='foto'
					accept=".png, .jpg, .jpeg"
				/>


				{
					categoria != null &&
						<label
							htmlFor="#is_disponibile"
							className='switch-wrap'
						>
							<span>è disponibile:</span>

						<Switch
							onChange={ () => setFormValuesByName( 'is_disponibile', !formValues.is_disponibile )}
							value={ formValues.is_disponibile }
							checked={ formValues.is_disponibile }
							id={'is_disponibile'}
							name='is_disponibile'
						/>
						</label>
				}



				{
					error != null &&

						<label style={{color:'red'}}>{error}</label>
				}


				<div
					className='btn-group'
				>

					<Button
						text="Conferma"
						icon={( <FaCheck/> )}
						color='green'
						onClick={ () =>  sendCategoria() }
					/>

					<Button
						text='Annulla'
						color='red'
						onClick={ showHadler }
						icon={( <FaTimes/> )}
					/>

				</div>

			</form>

		</Modal>
	)

}


export default CreateCategoryModal 
