import {Toaster} from 'react-hot-toast';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import AdminPage from './components/pages/AdminPage';
import LoginPage from './components/pages/LoginPage';
import MenuPage from './components/pages/MenuPage';
import OrdersPage from './components/pages/OrdersPage';
import PaymentPage from './components/pages/PaymentPage';
import { OrderContextProvider } from './components/context/OrderContext'
import AccountsPage from './components/pages/AccountsPage';
import GestioneMenuPage from './components/pages/GestioneMenuPage';

function App() {




  return (
    <>

	    <Toaster/>
	    <OrderContextProvider>
	    <Routes>
		    <Route path='*' element={<h1>not found</h1>} />
		    <Route path='/admin' element={ <AdminPage/> }>
			    <Route path='orders' element={ <OrdersPage/> }/>
			    <Route path='accounts' element={ <AccountsPage/> }/>
			    <Route path='menu' element={ <GestioneMenuPage/> } />
			    <Route path='login' element={ <LoginPage/> }/>
		    </Route>

		    <Route 
			path='/menu/:params' 
		    	element={ <MenuPage/> }
		    />
		    <Route path='/payment' element={ <PaymentPage/> }/>
	    </Routes>
	    </OrderContextProvider>

    </>
  );
}

export default App;
