import Modal from "../wrapper/Modal"
import Button from '../button/Button'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import {useCallback, useEffect, useRef, useState} from "react"
import { useForm, useGet, usePost } from '../../Hooks'
import TextInput from "../input/TextInput"
import {CREATE_ARTICOLO_URL, CREATE_CATEGORY_URL, CREATE_USER_URL, EDIT_ARTICOLO_URL} from "../../Costants"
import StarFoodArticoli from "./StarFoodArticoli"
import TextArea from "../input/TextArea"
import DigitInput  from "../input/DigitInput"

import { MdOutlineEuroSymbol } from 'react-icons/md'
import {useOutletContext} from "react-router-dom"


const CreateArticoloModal = ({ showHadler, db, articoloEdit }) => {


	const formRef = useRef()


	const [ {articolo, selectedPortata }, setMenuContext ] = useOutletContext()


	const [ postData, setPostData ] = useState({ url:null, query:null})
	const pState  =  usePost( postData )


	const [ error, setError ] = useState(null)



	const [
		formValues,
		setFormValues,
		setFormValueByEvent,
		setFormValuesByName,
	] = useForm( articoloEdit != null ? articoloEdit : {
		descrizione:"",
		prezzo:0.00,
		extra:""
	}) 



	useEffect( () => {

		if ( articolo != null && articoloEdit == null ){

			const values = {
				descrizione:articolo.NomePiatto,
				prezzo:articolo.Prezzo,
				area:selectedPortata?.Area,
				cod_piatto:articolo.CodPiatto,
				cod_portata:articolo.CodPortata,
				gest_qta:articolo.GestQta,
				c_art:articolo.CArt,
				categoria_id:articolo.categoria_id
			}

			setFormValues( old => {
				return {
					...old,
					...values
				}
			})

		}
	}, [articolo, selectedPortata])



	const sendArticolo = () => {
		const articolo = {
			...formValues,
			prezzo:Number(formValues.prezzo)
		}
		setPostData({
			url:articoloEdit == null ? CREATE_ARTICOLO_URL : EDIT_ARTICOLO_URL,
			query:articolo,
		})
	}


	useEffect( () =>{
		if ( pState?.data?.status === 'ok' ) {

			setMenuContext( old => {
				const newMenu = old.menu_data.map( ([ cat, articoli]) => {

					let newArticoli = [ ...articoli ]

					if ( cat.id === pState.data.data.categoria_id ){
						if ( articoloEdit != null ){
							newArticoli = articoli.filter( art => art.id !== pState.data.data.id )
						}
						newArticoli.push( pState.data.data )
					}
					return [ cat, newArticoli ]
				})

				return {
					...old,
					menu_data:newMenu
				}

			})
			showHadler()
		}
		else if ( pState?.error != null ){
			setError( pState?.error?.error?.join('\n') )
		}
	}, [
		pState?.data?.status,
		pState?.error
	])


	return (

		<Modal
			showHadler={ showHadler }
		>
			<form 
				className='articolo-form'
				ref={formRef}
			>

				<h1
					style={{ 'color':'var(--senape)'}}
				>
					{
						articoloEdit == null ?
							"Nuovo articolo":
							"Modifica aricolo"
					}
				</h1>


				{
					articoloEdit == null &&
						<StarFoodArticoli/>
				}



				<div className='input-group'>

					<TextInput
						label='Nome'
						id='descrizione'
						name='descrizione'
						value={formValues.descrizione}
						setValueByEvent={ event => setFormValueByEvent(event) }
					/>

					<DigitInput
						label='Prezzo'
						id='prezzo'
						name='prezzo'
						value={formValues.prezzo }
						setValueByEvent={ event => setFormValueByEvent(event) }
						icon={(<MdOutlineEuroSymbol/>)}
						setValue={ ( name, value) => setFormValuesByName( name, value )}
					/>


					<TextArea
						label='Info extra'
						id='extra'
						name='extra'
						value={formValues.extra}
						setValueByEvent={ event => setFormValueByEvent(event) }
					/>

				</div>


				{
					error != null &&

						<label style={{color:'red'}}>{error}</label>
				}


				<div
					className='btn-group'
				>

					<Button
						text="Conferma"
						icon={( <FaCheck/> )}
						color='green'
						onClick={ () =>  sendArticolo() }
					/>

					<Button
						text='Annulla'
						color='red'
						onClick={ showHadler }
						icon={( <FaTimes/> )}
					/>

				</div>

			</form>

		</Modal>
	)

}


export default CreateArticoloModal 
