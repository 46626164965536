import {useContext, useEffect, useMemo, useRef, useState} from "react"
import placeholder from  '../../static/header.jpg'
import {IoIosArrowDown} from 'react-icons/io'
import ArticoliList from "./ArticoliList"
import {OrderContext} from "../../context/OrderContext"
import {STATIC_URL} from "../../Costants"


const MenuCat = ({ categoria, articoli, color, idx,  menuState, menuDispatch }) => {


	const [ orderArticoli, ]  = useContext(OrderContext)

	const imgRef = useRef()
	const nameRef = useRef()
	const listRef = useRef()

	// const [isListOpen, setIsListOpen ] = useState(false)

	

	const toggleMenu = () => {
		if ( menuState.categoria === idx ){
			menuDispatch({ type:"CLOSE_MENU"})
		} else {
			menuDispatch({ type:"OPEN_MENU", categoria:idx})
		}
	}


	const renderTotQtCat = () =>  {

		const qt = orderArticoli.articoli
				.filter(  ordArt =>  articoli.map( art => art.id ).includes( ordArt.id ) )
				.reduce( ( accum, ordArt ) => accum + ordArt.quantita, 0 ) ?? 0



		if ( qt > 0 ){
			return (
				<div 
					className='cat-tot-qt'
					style={{
						backgroundColor:`var(--${color})`
					}}
				>
					{qt}
				</div>
			)
		}
		else return null
 
	}



	useEffect(() => {
		const fotoUrl = new URL( `${categoria.database_id}/${categoria.path_foto}`,  STATIC_URL )
		nameRef.current.style.backgroundColor = `var(--${color})`
		imgRef.current.style.backgroundImage = `url(${fotoUrl.href})`
	}, [color, categoria ])



	useEffect( () => {
		if ( menuState.categoria === idx ){
			const listHeigh = 89 * articoli.length ?? 0
			listRef.current.style.height = `${listHeigh}px`
		}
		else {
			listRef.current.style.height = `0px`
		}
	}, [ 
		menuState,
		idx,
		articoli.length
	])


	return(
		<>
		<article 
			className='categoria'
			onClick={ toggleMenu }
		>


			<div 
				className='nome-wrap'
				ref={nameRef}
			>

				<div 
					className="nome-cat"
				>
					<label>
						{categoria.nome.toUpperCase()}

					</label>


				</div>
			</div>

			<div 
				ref={imgRef}
				className="img-container"
			>

			</div>


			<div 
				className="arrow-container"
			>


			</div>

			<i className="arrow"><IoIosArrowDown/></i>

			{ renderTotQtCat() }

		</article>

		<div 
			ref={listRef}
			className='articoli-list-wrap'
		>
			<ArticoliList
				articoli={ articoli}
			/>
		</div>
		</>
	)
}


export default MenuCat 
