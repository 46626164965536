import {useContext, useEffect, useMemo, useReducer, useState} from "react"
import {useOutletContext} from "react-router-dom"
import {GET_USERS_URL} from "../Costants"
import {useGet} from "../Hooks"
import AccountRow from "../views/accounts/AccountRow"
import AccountList from "../views/accounts/AccountsList"
import CreateUserModal from "../views/accounts/CreateUserModal"
import DeleteAccountModal from "../views/accounts/DeleteAccountModal"
import Button from "../views/button/Button"
import Loader from "../views/loader/Loader"






const modalReducer = ( oldState, action ) => {
	switch( action.type ){
		case "DELETE":
			return {
				...initialModalValues,
				delete:true,
				user:action.user,
			}
		case "EDIT":
			return {
				...initialModalValues,
				edit:true,
				user:action.user,
			}
		case "CREATE":
			return {
				...initialModalValues,
				create:true,
			}

		case "CLOSE":
			return initialModalValues

		default:
			break
	}
}


const initialModalValues ={
	create:false,
	edit:false,
	delete:false,
	user:null,
}


const AccountsPage = () => {



	const [ modalState, modalDispatch ] = useReducer(modalReducer, initialModalValues )

	const [ getData, setGetData ] = useState({url:null})
	const gState = useGet( getData)



	const [ { accounts, } , setMenuContext ] = useOutletContext()


	useEffect(()=>{
		setGetData({url:GET_USERS_URL})
	},[])

	useEffect( () => {

		if ( gState?.data?.status === 'ok'){
			setGetData({url:null})
			setMenuContext( old => {
				return {
					...old,
					accounts:gState.data.data
				}
			})
		}
	}, [gState?.data?.data ])



	const accountsRender = useMemo(() => {
		return accounts?.map(( acc,idx) => {
			return (
				<AccountRow
					key={idx}
					account={ acc }
					modalDispatch={modalDispatch}
				/>
			)
		})

	}, [accounts])


	return (
		<main className='page accounts'>

			{
				modalState.delete === true &&
					<DeleteAccountModal
						showHadler={ () => modalDispatch({type:'CLOSE'})}
						item={ modalState.user }
					/>
			}

			{
				modalState.create === true &&
					<CreateUserModal
						showHadler={ () => modalDispatch({type:'CLOSE'})}
					/>
			}


			{
				modalState.edit === true &&
					<CreateUserModal
						user={ modalState.user }
						showHadler={ () => modalDispatch({type:'CLOSE'})}
					/>
			}

			{

				gState?.loading === true ?

					<Loader/>:
					<>
			<AccountList 
				accounts={accountsRender}
			/>

			<Button
				text="NUOVO"

				onClick={ () => modalDispatch({ type:'CREATE'})}
			/>
					</>
			}

		</main>
	)
}


export default AccountsPage 
