
import Button from '../button/Button'
import {useContext, useEffect, useMemo, useState} from 'react'
import {useOutletContext} from 'react-router-dom'



const PortateSection = ({portate}) => {


	const [ repBar, setRepBar ] = useState(false )

	const [ {selectedPortata, }, setMenuContext ] = useOutletContext()

	const renderPortate = useMemo(() => {

		let portateList  = []
		if ( repBar === true ){
			portateList = portate?.filter( por => por.Area === 'Bar')
		}
		else{
			portateList = portate?.filter( por => por.Area !== 'Bar')
		}
		return(
			<ul>
			{
				portateList.map( ( port, idx) => {
					return( <li

						className={ selectedPortata.CodPortata === port.CodPortata ? 'selected-portata' : "" }
						key={`portata_${port.CodPortata}`}
						onClick={ () => { 

							setMenuContext( old => { 
								return {
									...old,
									selectedPortata:port,
								}
							})
						}}
						>
						{port.Descrizione}
						</li>
					)
				})
			}
			</ul>
		)
	}, [portate, repBar, selectedPortata])



	return(
		<aside className='portate-sec'>

			<div>

				{ renderPortate }
				<Button
					text='Cucina'
					onClick={ () => setRepBar(false)}
				/>
				<Button
					text='Bar'
					onClick={ () => setRepBar(true)}
				/>
			</div>


		</aside >
	)
}


export default PortateSection
