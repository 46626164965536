
import { useEffect, useMemo, useReducer, useRef, useState} from 'react'
import {useOutletContext} from 'react-router-dom'






const PiattiSection = ({ piatti }) => {

	const [ {selectedPortata, }, setMenuContext ] = useOutletContext()


	const renderPiatti = useMemo(() => {

		if ( selectedPortata == null ) return 
		return (
			<ul 
				id='piatti-list'
			>
			{
				 piatti?.filter( piatto => piatto.CodPortata === selectedPortata?.CodPortata )
					.map( (piatto, idx) => {

						return(
							<li
								id={`piatto_${piatto.CodPiatto}`}
								key={`piatto_${piatto.CodPiatto}`}
								onClick={ event => {
									event.preventDefault()

									const articolo = {
										...piatto,
										area:selectedPortata.Area
									}

									setMenuContext( old => {
										return {
											...old,
											articolo:{
												...old.articolo,
												...articolo,

											},
										}
									})
								}}
							>
							{piatto.NomePiatto}
							</li>
						)
			})
			}
			</ul>
		)
	}, [ 
		piatti,
		selectedPortata
	])



	return(
		<aside className='piatti-sec'>

			{renderPiatti}

		</aside >
	)
}


export default PiattiSection
