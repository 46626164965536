import {useEffect, useState} from "react"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {useAuthCheck} from "../Hooks"

import Background from "../views/background/Background"
import Sidebar from "../views/sidebar/Sidebar"







const AdminPage = () => {


	useAuthCheck()


	const [ adminContext, setAdminContext ] = useState({})

	const location = useLocation()
	const navigate = useNavigate() 

	useEffect(()=>{
		if ( location.pathname === '/admin' || location.pathname === '/admin/'	){
			navigate('/admin/login')
		}
	},[])


	return (
		<>

			<Outlet context={[ adminContext, setAdminContext ]} />


			{
				! location.pathname.includes("login") &&
					<Sidebar/>
			}

			<Background/>
		</>
	)
}


export default AdminPage
