import {useEffect, useState} from "react"
import {useOutletContext} from "react-router-dom"
import {GET_ARTICOLI_URL} from "../../Costants"
import {useGet} from "../../Hooks"
import PiattiSection from "../comanda/PiattiSection"
import PortateSection from "../comanda/PortateSections"
import Loader from "../loader/Loader"
import '../comanda/Articoli.css'



const StarFoodArticoli = () => {




	const [ menuContext, setMenuContext ] = useOutletContext()



	const [ piatti, setPiatti ] = useState([])
	const [portate, setPortate ] = useState([])

	const [ getData, setGetData ] = useState({ url:null })
	const gState = useGet(getData)


	useEffect(() => {
		const url = new URL( `${menuContext.db}/`, GET_ARTICOLI_URL )
		setGetData({ url:url })
	}, [])


	useEffect( () => {

		if ( gState?.data?.status === 'ok'){

			setPiatti(
				gState.data.data?.piatti
			)
			setPortate(
				gState.data.data?.portate
			)

			setMenuContext( old => {
				return {
					...old,
					selectedPortata:gState.data.data?.portate[0]
				}
			})
		}
	}, [ gState?.data?.status ])







	return (
		<div className='starfood-art'>

			{
				gState.loading === true ?
					<Loader/>:
					<>
					<PortateSection 
						portate={portate}

					/>

					<PiattiSection
						piatti={piatti}
					/>





					</>
			}



		</div>
	)
}


export default StarFoodArticoli
