import {useContext, useMemo} from "react"
import {OrderContext} from "../../context/OrderContext"
import RiepilogoItem from "./RiepilogoItem"





const RiepilogoOrdine = () => {


	const [ ordine, ] = useContext(OrderContext)


	const renderArt = useMemo(() => {
		return ordine.articoli.map( (ordArt, idx ) => {

			return (
				<RiepilogoItem
					articolo={ordArt}
				/>
			)
		})

	}, [ordine?.articoli])

	return ( 

		<section className='riepilogo-ordine'>


			<h3>RIEPILOGO ORDINE</h3>



			<ul>
				{ renderArt }
			</ul>

		</section>
	)
}

export default RiepilogoOrdine
