
import { useCallback, useRef, useEffect, useMemo } from 'react'

import './Button.css'




const Button = ({ 
	text,
	icon,
	onClick,
	color,
	disabled=false,
	isMulti,
	
}) => {



	const classname = useMemo( () => {
		let base = 'button-wrap'
		if (isMulti === true){
			base+=' btn-multi'
		}
		return base

	}, [isMulti])


	

	const createRipple = useCallback( event => {
		const button = event.currentTarget;
		const ripple = button.getElementsByClassName("ripple")[0];
		if (ripple) {
		ripple.remove();
		}
		const circle = document.createElement("span");
		const diameter = Math.max(button.clientWidth, button.clientHeight);
		const rect = event.target.getBoundingClientRect()
		const trgY = rect.y
		const trgX = rect.x
		const clickX  = event.clientX
		const clickY = event.clientY
		const backX = clickX - trgX 
		const backY = clickY - trgY 

		circle.style.width = circle.style.height = `${diameter}px`;
		circle.style.left = `${backX}px`;
		circle.style.top = `${backY}px`;
		circle.classList.add("ripple");

		button.appendChild(circle);
	}, [])


	return (
		<div
			className={ classname }
		>
			<button
				disabled={ disabled }
				className={ `button ${ color != null ? color : '' }` }
				onTransitionEnd={ event => event.stopPropagation() }
				onClick={ event => {
					event.preventDefault()
					event.stopPropagation()
					createRipple( event )
					onClick()
				}}
				onKeyDown = { event => {
					event.preventDefault()
					event.stopPropagation()
				}}
			>
				{ text }


				<i>
					{ icon && icon }
				</i>

			</button>

		</div>
	)
}




export default Button
