import {useContext, useMemo} from "react"
import { IoTrashOutline } from 'react-icons/io5'

import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'


const RiepilogoOrderItem = ({ articolo }) => {





	return (
		<li 
			className='articolo-item riepilogo'
		>
			<div className='articolo-item-qt'>
				<label>x{articolo.quantita}</label>
			</div>


			<div className='articolo-item-text'>
				<p>
				{ articolo?.descrizione}
				</p>
				<label>
					{ Number( articolo?.prezzo).toFixed(2) } €
				</label>
			</div>


		</li>
	)
}


export default RiepilogoOrderItem 






