import Modal from "../wrapper/Modal"
import Button from '../button/Button'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import {useCallback, useEffect, useRef, useState} from "react"

import { useForm, usePost } from '../../Hooks'

// import {useOutletContext} from "react-router-dom"
import TextInput from "../input/TextInput"
import {CREATE_USER_URL, EDIT_USER_URL} from "../../Costants"
import {useOutletContext} from "react-router-dom"
import Loader from "../loader/Loader"





const CreateUserModal = ({ showHadler, user }) => {

	const formRef = useRef()
	const [ postData, setPostData ] = useState({ url:null, query:null})
	const pState  =  usePost( postData )

	const [ error, setError ] = useState(null)
	const [ , setMenuContext ] = useOutletContext()

	const [
		formValues,
		setFormValues,
		setFormValueByEvent,
		setFormValuesByName,
	] = useForm( user != null ? user : {
		username:"",
		password:""
	}) 


	const sendUser = () => {
		setPostData({
			url: user != null ? EDIT_USER_URL : CREATE_USER_URL,
			query:formValues,
		})
	}

	useEffect( () => {
		if ( pState?.data?.status === 'ok'){
			setMenuContext( old => {
				let newAccounts = [ ...old.accounts]

				if ( user != null ){
					newAccounts = newAccounts.filter(acc => acc.id !== pState.data.data.id )
				}
				newAccounts.push( pState.data.data )
				return {
					...old,
					accounts:newAccounts
				}
			})

			showHadler()
		}
		setPostData({ url:null, query:null })
	}, [ pState?.data?.status, user ])


	useEffect( () => {
		if ( pState?.error?.status === "fail"){
			if ( pState.error.error[0].includes('user_username_key')) {
				setError("username non disponibile")
			}
			else{
				setError( pState.error.error )
			}
		}
		setPostData({ url:null, query:null })
	}, [pState?.error?.status ]) 





	return (

		<Modal
			showHadler={ showHadler }
		>
			<form 
				className='user-form'
				ref={formRef}
			>

				<h1
					style={{ 'color':'var(--senape)'}}
				>
					{
						user != null ?
							"Modifica utente":
							"Nuovo utente"
					}

				</h1>


				<TextInput
					label='Username'
					value={ formValues.username }
					setValueByEvent={ event => setFormValueByEvent(event) }
					name='username'

				/>

				<TextInput
					label='Password'
					value={ formValues.password }
					setValueByEvent={ event => setFormValueByEvent(event) }
					name='password'

				/>


				{
					error != null &&
						<label
							style={{color:'red'}}
						>
							{error}
						</label>
				}

				{

					pState.loading === true ?
						<Loader/>:
						<div
							className='btn-group'
						>

							<Button
								text="Conferma"
								icon={( <FaCheck/> )}
								color='green'
								onClick={ () =>  sendUser() }
							/>

							<Button
								text='Annulla'
								color='red'
								onClick={ showHadler }
								icon={( <FaTimes/> )}
							/>

						</div>
				}
			</form>

		</Modal>
	)

}


export default CreateUserModal 
