import TextInput from "../views/input/TextInput"
import Card from "../views/wrapper/Card"
import Button from "../views/button/Button"
import {useEffect, useState} from "react"
import {useForm, useGet, usePost} from "../Hooks"
import {GET_DB_URL, LOGIN_URL} from "../Costants"
import useSessionStorageState from "use-session-storage-state"
import {useNavigate} from "react-router-dom"
import Loader from "../views/loader/Loader"





const LoginPage = () => {


	const [ user, setUser ] = useSessionStorageState('user', { defaultValue:null})
	const navigate = useNavigate()

	const [ postData, setPostData ] = useState({ url:null, query:null})
	const pState  =  usePost( postData )

	const [ getData, setGetData ] = useState({ url:null })
	const gState =  useGet( getData )


	const [
		formValues,
		setFormValues,
		setFormValueByEvent,
		setFormValuesByName,
	] = useForm({
		username:"",
		password:""
	}) 


	const sendUser = () => {
		setPostData({
			url:LOGIN_URL,
			query:formValues,
		})
	}




	useEffect(()=>{
		if ( pState?.data?.status === 'ok'){
			const role = pState?.data?.data.role
			setUser( old => {
				return {
					...old,
					role:role,
					id:pState.data.data.id,
				}
			})
			setGetData({url:GET_DB_URL})
		}

		setPostData({ url:null, query:null})
	},[pState?.data?.status])



	useEffect(()=>{
		if ( gState?.data?.status === 'ok'){
			setUser( old => {
				return {
					...old,
					db:gState?.data?.data?.db
				}
			})
			navigate("/admin/orders")
		}
		setGetData({url:null})
	},[gState?.data?.status])












	return (
		<div className='page login'>


		{

		pState?.loading === true || gState.loading === true ?
			<Loader/>:
			<Card>

				<div className='login-card'>

				<TextInput
					label='Utente'
					name='username'
					value={ formValues.username }
					setValueByEvent={ event => setFormValueByEvent( event )}

				/>

				<TextInput
					label='Password'
					password={true}
					name='password'
					value={ formValues.password}
					setValueByEvent={ event => setFormValueByEvent( event )}
				/>


				<Button
					text='ENTRA'
					onClick={sendUser}
				/>

				</div>

			</Card>

		}
		</div>
	)
}


export default LoginPage
