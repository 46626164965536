import './Input.css'
import {   useRef, useCallback, useEffect } from 'react'


const TextInput = ({  
	id,
	name,
	label,
	icon,
	value,
	setValue,
	setValueByEvent,
	disabled=false,
	onblur=null,
	onfocus=null,
	initialValue,
	short="",
}) => {


        const btnDownRef = useRef()
        const btnUpRef = useRef()
	const spinnerRef = useRef()


	const wrapRef = useRef()
	const inputRef = useRef()
	const labelRef = useRef()
	const topRef = useRef()
	const leftRef = useRef()
	const rightRef = useRef()
	const bottomRef = useRef()
	const iconRef = useRef()



	const toggleActiveDigit = useCallback( ( toggle ) => {
		if ( toggle === true ){
			inputRef.current.classList.add( 'text-input-active' )
			topRef.current.classList.add( 'border-tb-active' )
			bottomRef.current.classList.add( 'border-tb-active' )
			leftRef.current.classList.add( 'border-lr-active' )
			rightRef.current.classList.add( 'border-lr-active' )
		}
		else{
			inputRef.current.classList.remove( 'text-input-active' )
			topRef.current.classList.remove( 'border-tb-active' )
			bottomRef.current.classList.remove( 'border-tb-active' )
			leftRef.current.classList.remove( 'border-lr-active' )
			rightRef.current.classList.remove( 'border-lr-active' )
		}
	}, [ ] )



         useEffect( () =>{

		 if ( disabled === true ) return

		 const _step = 1
		 const _min = 0			 
		 spinnerRef.current = document.createElement('div')

		 spinnerRef.current.classList.add( 'quantity-button' )

		 btnDownRef.current = document.createElement('div')
		 btnDownRef.current.classList.add( 'quantity-down' )
		 btnDownRef.current.innerHTML = '-'

		 btnUpRef.current = document.createElement( 'div')
		 btnUpRef.current.classList.add('quantity-up')
		 btnUpRef.current.innerHTML = '+'

		 btnUpRef.current.onclick = () => {
			 // if ( disabled === true )
				//  return null
			 var oldValue = parseFloat( inputRef.current.value );
			 if ( isNaN(oldValue) ) oldValue = 0.0
			 var newVal = oldValue + _step;

			 // if ( max != null && newVal <= max ){
				// setValue( name,  `${newVal}`)
			 // }
			 // else if( max == null ){
				// setValue(name,  `${newVal}`)
			 // }
			 // else{
			setValue( name,`${newVal}`)
			 // }

			 //inputRef.current.focus()
			 
		 };

		 btnDownRef.current.onclick = () => {
			 // if ( disabled === true )
				//  return null
			 var oldValue = parseFloat( inputRef.current.value );
			 if ( isNaN(oldValue) ) oldValue = 0.0
			 var newVal = oldValue - _step;
			 if ( newVal >=  _min ){
				 setValue( name, `${newVal}`)
			 }
			 //inputRef.current.focus()
			 
		 };

		 spinnerRef.current.append( btnUpRef.current )
		 spinnerRef.current.append( btnDownRef.current )
		 inputRef.current.parentNode.insertBefore( spinnerRef.current, inputRef.current.nextSibling);


		return () =>{
				 btnUpRef.current.remove() 
				 spinnerRef.current.remove() 
				 btnDownRef.current.remove() 
		 }
 
         }, [ 
		 disabled,
		 setValue,
		 name,
	 ] )


	return (

		<div 
			className={ `text-input-wrap ${short}`}
			ref={ wrapRef }
		>


			<label 
				className={ 'label-fix' }
				ref={ labelRef }
				onClick={ () =>{
					inputRef.current.focus()
				}}
				htmlFor={ id }
			>
				{label}
			</label>



			<input
				id={ id }
				className={`text-input ${short}`}
				name={ name }
				ref={ inputRef	}
				disabled={ disabled }
				type= 'number' 
				value={ value }
				autoComplete='off'
				data-number={  'number' }
				lang="en_EN" 
				onKeyDown={ event => {
					if( event.keyCode === 13 ){
						event.stopPropagation()
						event.preventDefault()
					}
				}}
				onKeyUp={ event => {
					event.stopPropagation()
					event.preventDefault()
				}}

				onFocus={ event => {
						toggleActiveDigit( true )
						event.target.select()

					onfocus &&
						onfocus(event)
				}}


				onBlur={ event => {
					toggleActiveDigit( false )
					if ( 
						event.target.value === ''
					){

						event.target.value = initialValue ?? 0
						setValueByEvent( event )
					}

					onblur &&
						onblur( event )
				}}

				onInput={ event => {
					const val = event.target.value
					// if ( max != null ){
					// 	if (  parseFloat(val) >  parseFloat( max) ){
					// 		setValue(name,  max )
					// 		return
					// 	}
					// }
					setValueByEvent( event )

				}}

			/>

			<div 
				className={`border-wrap ${short}`}
			> 
				<div 
					className='border border-top'
					ref={ topRef }
				/>
				<div 
					className='border border-left'
					ref={ leftRef }
				/>
				<div 
					className='border border-right'
					ref={ rightRef }
				/>
				<div 
					className='border border-bottom'
					ref={ bottomRef }
				/>

			</div>

			{ 
				icon &&	<span 
						className='text-input-icon'
						ref={ iconRef }
					> 
					{icon}
					</span>
			}

			{
				// errorLabel

			}



		</div>
	)
}


export default TextInput
























// import { NumericStepper} from '@anatoliygatt/numeric-stepper'
//
//
// const DigitInput = ({ name, setValue, label, initialValue }) => {
//
//
// 	return (
//
// 		<span
// 			className='digit-input'
// 		>
// 			<label 
// 				htmlFor={`#${name}`}
// 				className='digit-label'
// 			>
// 			{label}</label>
//
// 		<NumericStepper
// 			// initialValue={ initialValue }
// 			stepValue={1}
// 			minimumValue={0}
// 			onChange={ ( value ) => setValue( name, value )}
// 			//thumbColor='var( --senape )'
// 			//inactiveTrackColor='#FFBA08'
// 			inactiveTrackColor='#e5e5f7'
// 			
// 			size='sm'
// 		>
//
// 		</NumericStepper>	
//
// 		</span>
// 	)
// }
//
//
// export default DigitInput 


/*
 *

			<input
				id={name}
				name={ name }
				value={ value }
			/>
			*/
