import Card from "../wrapper/Card"

import { CiEdit } from 'react-icons/ci'

import { IoTrashOutline } from 'react-icons/io5'
import {useMemo} from "react"
import useSessionStorageState from "use-session-storage-state"




const AccountRow = ({ account, modalDispatch }) => {


	const [ user, ] = useSessionStorageState('user')


	const editIcons = useMemo(() => {
		if ( user.role === 1 || user.id === account.id ){
			return (
				<div>

					<i
						onClick={() => modalDispatch({type:"EDIT", user:account })}
					>
						<CiEdit/>
					</i>
					<i
						onClick={() => modalDispatch({type:"DELETE", user:account })}
					>
						<IoTrashOutline/>
					</i>
				</div>
			)
		}
		else{
			return null
		}
	}, [ user ])


	return (

		<Card 
			// style={{ width:'100%'}}
		>
		<div className='account-row'>

			<p>

			{ account.username }
			</p>

			{editIcons}

		</div>

		</Card>
	)
}

export default AccountRow
