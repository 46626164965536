import {useCallback, useMemo, useRef} from "react"
import SidebarItem from "./SidebarItem"
import './Sidebar.css'

import logoKing from "../../static/logo-king.png"; 

import { RxHamburgerMenu } from 'react-icons/rx'


const MENU = [

	{
		text:'ORDINI',
		href:'orders'
	},
	{
		text:"GESTIONE MENU'",
		href:"menu"
	},
	{
		text:"ACCOUNTS",
		href:'accounts'
	},
	{
		text:"LOGOUT",
		href:''
	},
]




const Sidebar = () => {


	const sidebarRef = useRef()


	const toggleSidebar = useCallback( () => {
		sidebarRef.current.classList.toggle('sidebar-active')
	},[] )


	const menu = useMemo(  () => {
		return MENU.map(( item, idx) => {
			return ( 
				<SidebarItem 
					item={item}
					key={idx}
					toggleSidebar={ toggleSidebar }
				/> 
			)
		})
	}, [])


	return ( 

		<div className='sidebar-wrap'>

		<aside 
			className='sidebar'
			ref={ sidebarRef }
		>
			<div>
				<img 
					src={`${logoKing}`}
					alt='logo'
				/>	
			</div>

			<ul>
				{ menu }
				
			</ul>

		</aside>



			<i
				onClick={ toggleSidebar }
			>

				<RxHamburgerMenu/>

			</i>


		</div>
	)
}


export default Sidebar
