import {useEffect, useMemo, useState} from 'react'
import Card from '../wrapper/Card'
import RiepilogoOrderItem from './RiepilogoOrderItem'
import { BsCashStack } from 'react-icons/bs'
import { FaCreditCard } from 'react-icons/fa'
import Button from  '../button/Button'
import {usePost} from '../../Hooks'
import {CONSEGNA_ORDINE_URL} from '../../Costants'
import {useOutlet, useOutletContext} from 'react-router-dom'
import {toast} from 'react-hot-toast'



const OrderDetails = ({
	ordersState,
	ordersDispatch,
}) =>  {



	const [ , setOrdiniContext ] = useOutletContext()
	const [ postData, setPostData ] = useState({ url:null, query:null })
	const pState = usePost( postData )

	const [ ordine, articoli ] = ordersState?.selected ?? [ null, []]

	const renderArt = useMemo(() => {
		if ( articoli.length === 0 ) return 
		return articoli.map( (ordArt, idx ) => {
			return (
				<RiepilogoOrderItem
					articolo={ordArt}
					key={idx}
				/>
			)
		})
	}, [ articoli.length ])




	const consegnaOrdine = () => {
		setPostData({ url:CONSEGNA_ORDINE_URL, query:{ ordine_id: ordine.id }})
	}


	useEffect(() => {
		if ( pState?.data?.status === 'ok'){
			setOrdiniContext( old => {
				let newOrdini = [ ...old.ordini ]
				newOrdini  = newOrdini.filter( ( [ord, ] ) => ord.id !== pState?.data?.data?.ordine_id )
				return {
					...old,
					ordini:newOrdini
				}
			})
			toast.success(`Ordine ${pState?.data?.data?.ordine_id} consegnato!`)
			setPostData({ url:null, query:null})
			ordersDispatch({type:'CLOSE'})
		}
	}, [pState?.data?.status ])


	if ( ordersState?.selected == null){
		return null
	}
	else {

		return (
			<Card
				style={{padding:'30px 20px'}}
			>
			<section className='riepilogo-ordine'>

				<label>{ ordine.tav_desc_breve} </label>

				<div>
					<span>
						<p>#{ordine.id}</p>
					</span>

					<i>
				{
					ordine.pagamento === 'pos' ?
						<FaCreditCard/>:
						<BsCashStack/>
				}

					</i>
				</div>


				<ul>
					{ renderArt }
				</ul>


				{
					ordine.note !== '' &&
						<div>
							<label>Note</label>
							<p>{ordine.note}</p>
						</div>
				}
				<h3>{ Number(ordine.totale).toFixed(2) }€</h3>


				<Button
					text='CONSEGNATO'
					color='red'
					onClick={ () => consegnaOrdine() }
				/>

			</section>
			</Card>
		)
	}
	
}




export default OrderDetails
