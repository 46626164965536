import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import './Select.css'

import useSessionStorageState from 'use-session-storage-state'
import { useOutletContext} from 'react-router-dom'


const SelectDb = ({ 
	value,
	setValue,
	name,
	placeholder,
}) => {


	const [ outletContext, setOutletContext ] = useOutletContext()

	const [ user, ] = useSessionStorageState('user', { defaultValue:null})

	const options = useMemo( () => {
		if ( user == null || user?.db == null) return 
		return user.db.map( (db, i ) => {
			return  (

				<option 
					value={db.id}
				>
					{db.descrizione}

				</option>

			)
		})
	}, [user?.db ])

	const setSelectedValue = useCallback(( val ) => {
		setValue( name, val )
	}, [setValue, name])




	useEffect( () => {
		if ( value == null){
			setValue( outletContext?.params?.selectedDb ?? 1 )
		}
	},[])




	return (
		<span className='select-input'>

			<select
				value={value}
				onChange={ event => {
					setValue( event.target.value )

					setOutletContext( old => {
						return {
							...old,
							params:{
								...old.params,
								selectedDb:event.target.value
							}
						}
					})
				}}
			>
				{options}
			</select>
		</span>
	)
}

export default SelectDb
