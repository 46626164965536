import {useContext, useMemo} from "react"
import {OrderContext} from "../../context/OrderContext"
import { IoTrashOutline } from 'react-icons/io5'

import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'


const RiepilogoItem = ({ articolo }) => {


	const [ orderArticoli, , addArticolo, delArticolo ] = useContext(OrderContext)

	const renderQt = () =>{
		const qt = orderArticoli.articoli.find( ordArt => ordArt.id === articolo.id )?.quantita ?? 0
		if ( qt != 0 ){
			return (
				<div className='articolo-item-qt'>
					<label>x{qt}</label>
				</div>
			)
		}
		else return null
	}



	return (
		<li 
			className='articolo-item riepilogo'
		>

			{ renderQt() }

			<div className='articolo-item-text'>
				<p>
				{ articolo?.descrizione}
				</p>
				<label>
					{ Number( articolo?.prezzo).toFixed(2) } €
				</label>
			</div>


			<div>
				<i
					onClick={ () => addArticolo( articolo ) }
				>
					<AiOutlinePlus/>
				</i>

				<i
					onClick={ () => delArticolo( articolo ) }
				>
					<AiOutlineMinus/>
				</i>

			</div>

		</li>
	)
}


export default RiepilogoItem 






