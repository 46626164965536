import {useNavigate} from "react-router-dom"
import {useLogout} from "../../Hooks"







const SidebarItem = ({ item, toggleSidebar }) => {



	const navigate = useNavigate()
	const doLogout = useLogout()


	const click = () => {
		toggleSidebar()
 		navigate( item.href )
	}

	return ( 


		<li
			onClick={() => {
				if ( item.text !== 'LOGOUT'){
					click() 
				}
				else{
					doLogout()
				}
			}}
		>


			{item.text}

			
		</li>

	)
}


export default SidebarItem
