import Modal from "../wrapper/Modal"
import Button from '../button/Button'
import { FaTimes } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import { useEffect, useRef, useState} from "react"
import { useDelete, useForm,  usePost } from '../../Hooks'
import TextInput from "../input/TextInput"
import {CREATE_ARTICOLO_URL, DELETE_ITEM_URL } from "../../Costants"
import StarFoodArticoli from "./StarFoodArticoli"
import TextArea from "../input/TextArea"
import DigitInput  from "../input/DigitInput"
import { MdOutlineEuroSymbol } from 'react-icons/md'
import {useOutletContext} from "react-router-dom"







const DeleteItemModal = ({ showHadler, item,  }) => {


	const [ {articolo, selectedPortata }, setMenuContext ] = useOutletContext()


	const [ isArticolo, ] = useState( () => item?.prezzo != null ? true : false )
	const [ error, setError ] = useState(null)



	const [ delData, setDelData ] = useState({url:null})
	const dState = useDelete(delData)


	const deleteItem = () => {
		const url = new URL(  DELETE_ITEM_URL )
		const params = new URLSearchParams({ id:item.id })
		params.append('articolo', isArticolo )
		url.search = params.toString()
		setDelData({url:url})
	}


	useEffect( () =>{
		if ( dState?.data?.status === 'ok' ) {
			setMenuContext( old => {
				let newMenu = old.menu_data
				if ( isArticolo === false ){
					newMenu = old.menu_data.filter( ([cat,articoli]) => cat.id !== dState.data.data.id)
				}
				else{
					newMenu = old.menu_data.map( ([ cat, articoli]) => {
						const newArt = articoli.filter( art => art.id !== dState.data.data.id )
						return [ cat, newArt ]
					})
				}

				return {
					...old,
					menu_data:newMenu
				}
			})
			showHadler()
		}
		else if ( dState?.error != null ){
			setError( dState?.error?.error?.join('\n') )
		}
	}, [
		dState?.data?.status,
		dState?.error,
		setMenuContext, 
		isArticolo,
		showHadler,
	])


	return (

		<Modal
			showHadler={ showHadler }
		>
			<div className="articolo-form">


			<h1
				style={{ 'color':'var(--senape)'}}
			>
				{
					isArticolo === true ?
						"Elimina articolo":
						"Elimina categoria"
				}
			</h1>


			<p>
				Vuoi davvero eliminare { isArticolo === true ? "quest articolo": "questa categoria"} ?
			</p>


			{
				error != null &&

					<label style={{color:'red'}}>{error}</label>
			}


			<div
				className='btn-group'
			>

				<Button
					text="Conferma"
					icon={( <FaCheck/> )}
					color='green'
					onClick={ () =>  deleteItem() }
				/>

				<Button
					text='Annulla'
					color='red'
					onClick={ showHadler }
					icon={( <FaTimes/> )}
				/>

			</div>
			</div>
		</Modal>
	)

}


export default DeleteItemModal 
