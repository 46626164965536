import {  useMemo, useContext, useRef, useCallback, useLayoutEffect, useEffect, useState } from 'react'
import './Input.css'

import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

const TextInput = ({  
	id,
	label,
	icon,
	value,
	setValueById,
	setValueByEvent,
	searchHanlder,
	disabled=false,
	error,
	onblur=null,
	onfocus=null,
	name,

	password,
}) => {


	
	const wrapRef = useRef()
	const inputRef = useRef()
	const labelRef = useRef()
	const topRef = useRef()
	const leftRef = useRef()
	const rightRef = useRef()
	const bottomRef = useRef()
	const iconRef = useRef()



	const [ errors, setErrors ] = useState([])




	const errorLabel = useMemo( () => {

		if (
			searchHanlder != null
			&& error != null
		){
			return error
		}
		else if (
			( errors && errors[id] != null ) ||  
			(  error != null )    
		){
			return <label className='error-label'>{
				error != null ?
					error[id]:
					errors[id]
			}</label>
		}
		return null
	}, [ errors, error ] )


	const toggleLabel = useCallback( () => {
		if ( inputRef.current.value.trim().length === 0 ){
			if ( inputRef.current.contains(document.activeElement) ){
				labelRef.current.classList.add( 'text-input-label-active' )
			}
			else{
				labelRef.current.classList.remove( 'text-input-label-active' )
			}
		}
		else{
			labelRef.current.classList.add( 'text-input-label-active' )
		}
	} , [])



	const toggleActive = useCallback( () => {
		toggleLabel()
		inputRef.current.classList.toggle( 'text-input-active' )

		iconRef.current &&
			iconRef.current.classList.toggle( 'text-input-icon-active' )
		
		topRef.current.classList.toggle( 'border-tb-active' )
		bottomRef.current.classList.toggle( 'border-tb-active' )
		leftRef.current.classList.toggle( 'border-lr-active' )
		rightRef.current.classList.toggle( 'border-lr-active' )
	}, [toggleLabel] )



	useEffect( () =>{
		toggleLabel()
	}, [value]);



	const  [ viewPassword, setViewPssword ] = useState( false )


	const type = useMemo( () => {

		if ( password !== true ){
			return 'text'
		}
		else{

			if (viewPassword === true){
				return 'text'
			}
			else{
				return 'password'
			}
		}
	}, [password, viewPassword])


	const passwordIcon = useMemo(  () => {

		if ( viewPassword === true ){
			return (
				<i
					className='text-input-icon'
					onClick={ () => setViewPssword( old => !old )}
				>
				<AiOutlineEyeInvisible/>
				</i>
			)
		}
		else return (
				<i
					className='text-input-icon'
					onClick={ () => setViewPssword( old => !old )}
				>
				<AiOutlineEye/>
				</i>
			)
	}, [viewPassword])







	return (

		<div 
			className='text-input-wrap'
			ref={ wrapRef }
		>


			<label 
				className={  'text-input-label' }
				ref={ labelRef }
				onClick={ () =>{
					inputRef.current.focus()
				}}
				htmlFor={ id }
			>
				{label}
			</label>


			{
				password === true && passwordIcon

			}

			{ 
				icon &&	<span 
						className='text-input-icon'
						ref={ iconRef }
					> 
					{icon}
					</span>
			}

			<input
				id={ id }
				className='text-input'
				name={ name }
				ref={ inputRef	}
				disabled={ disabled }
				type={ type }
				value={ value }
				autoComplete='off'
				data-number={ 'text' }
				lang="en_EN" 
				onKeyDown={ event => {
					if( event.keyCode === 13 ){
						event.stopPropagation()
						event.preventDefault()
					}
				}}
				onKeyUp={ event => {
					event.stopPropagation()
					event.preventDefault()
					if( event.keyCode === 13 ){
					}
				}}

				onFocus={ event => {
					toggleActive()
					onfocus &&
						onfocus(event)
				}}


				onBlur={ event => {
					toggleActive()
					onblur &&
						onblur( event )
				}}

				onInput={ event => {
					setValueByEvent( event )
				}}

				onChange={ event => {
				} }
			/>
			<div 
				className='border-wrap'
			> 
				<div 
					className='border border-top'
					ref={ topRef }
				/>
				<div 
					className='border border-left'
					ref={ leftRef }
				/>
				<div 
					className='border border-right'
					ref={ rightRef }
				/>
				<div 
					className='border border-bottom'
					ref={ bottomRef }
				/>

			</div>

			{
				errorLabel

			}



		</div>
	)
}


export default TextInput
