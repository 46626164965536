import {useMemo} from 'react'
import { BsCashStack } from 'react-icons/bs'
import { FaCreditCard } from 'react-icons/fa'




const OrdersItem = ({ order, ordersDispatch }) => {



	const [ ordine, articoli ] = order 


	const data_rend = useMemo( () => {
		const ora_arr = [ ...ordine.ora].splice(0,3)
		const [ ora, min, sec ] = ora_arr
		const data = new Date( ordine.data ).toLocaleString().split(",")[0]
		const ora_str = `${ora}:${min}:${sec}`
		const res = {
			data:data,
			ora:ora_str
		} 
		return res
	}, [ ordine ])

	return ( 


		<li
			className='order-item'

			onClick={() => ordersDispatch({ type:'SELECT', order:order })}
		>


			<label>{ordine.tav_desc_breve}</label>


			<span
				style={{
					textAlign:'left'
				}}
			>
				<p>
				{ data_rend.data }
				</p>
				<p>
				{ data_rend.ora}
				</p>
			</span>

			<span>
			{ Number( ordine.totale).toFixed(2)}€
			</span>

			<i>
				{
					ordine.pagamento === 'pos' ?
						<FaCreditCard/>:
						<BsCashStack/>
				}
			</i>

			
		</li>

	)
}


export default OrdersItem
