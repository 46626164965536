import {useCallback, useEffect, useMemo, useState} from "react"
import Card from "../wrapper/Card"
import MenuCat from "./MenuCat"

import { useGet } from '../../Hooks'
import {SET_ARTICOLO_DISPONIBILE_URL} from "../../Costants"
import {useOutletContext} from "react-router-dom"




const MenuSection = () => {



	const [ { menu_data, } , setMenuContext ] = useOutletContext()

	const [ getData, setGetData ] = useState({url:null})
	const gState = useGet(getData)

	const [catActive, setCatActive] = useState(null)



	const setArticoloDisponibileCallback = useCallback(( artId )=>{
		const url = new URL(SET_ARTICOLO_DISPONIBILE_URL)
		const params = new URLSearchParams({ id:artId})
		url.search = params.toString()

		setGetData({url:url})

	},[ setGetData ])




	const catActiveCallback = useCallback((id) => {
		if ( id !== catActive ){
			setCatActive( id )
		}
		else {
			setCatActive(null)
		}
	}, [setCatActive, catActive ])



	useEffect( () => {
		if (gState?.data?.status === 'ok'){
			setMenuContext( old => {
				const newMenu = old.menu_data?.map( ([ cat, articoli]) => {
					const newArt = articoli.map( art => {
						if ( art.id === gState?.data?.data?.id ){
							art.is_disponibile = !art.is_disponibile
							const newArticolo = {
								...art,
								is_disponibile:gState?.data?.data?.is_disponibile
							}
							return newArticolo
						}
						else{
							return art
						}
					})
					return [cat, newArt ]
				})
				return {
					...old,
					menu_data:newMenu
				}
			})

			setGetData({url:null})
		}
	}, [gState?.data?.status, setMenuContext ])




	const menuCatItems = useMemo( () => {
		return menu_data?.map(  ( [ cat, articoli] , idx   ) =>{
			return (
				<MenuCat
					cat={cat}
					articoli={articoli}
					key={ idx }
					setCatActive={ () => catActiveCallback(cat.id) }
					catActive={ catActive }
				/>
			)
		})
	}, [ menu_data, catActiveCallback, catActive ])


	useEffect(()=>{
		setMenuContext( old => {
			return {
				...old,
				setDisponibile:setArticoloDisponibileCallback
			}
		})
	}, [setArticoloDisponibileCallback, setMenuContext ])




	return ( 
		<Card>
			<section className='menu-sec'>

			{menuCatItems}	
			</section>
		</Card>

	)
}



export default MenuSection
