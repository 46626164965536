





// 
			//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00af80" fill-opacity="1" d="M0,288L17.1,256C34.3,224,69,160,103,149.3C137.1,139,171,181,206,202.7C240,224,274,224,309,192C342.9,160,377,96,411,101.3C445.7,107,480,181,514,197.3C548.6,213,583,171,617,170.7C651.4,171,686,213,720,229.3C754.3,245,789,235,823,245.3C857.1,256,891,288,926,256C960,224,994,128,1029,117.3C1062.9,107,1097,181,1131,213.3C1165.7,245,1200,235,1234,197.3C1268.6,160,1303,96,1337,69.3C1371.4,43,1406,53,1423,58.7L1440,64L1440,0L1422.9,0C1405.7,0,1371,0,1337,0C1302.9,0,1269,0,1234,0C1200,0,1166,0,1131,0C1097.1,0,1063,0,1029,0C994.3,0,960,0,926,0C891.4,0,857,0,823,0C788.6,0,754,0,720,0C685.7,0,651,0,617,0C582.9,0,549,0,514,0C480,0,446,0,411,0C377.1,0,343,0,309,0C274.3,0,240,0,206,0C171.4,0,137,0,103,0C68.6,0,34,0,17,0L0,0Z"  data-darkreader-inline-fill=""></path></svg>



const Background = () => {



	return ( 

		<div className='background'>

			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00af80" fill-opacity="1" d="M0,32L48,48C96,64,192,96,288,133.3C384,171,480,213,576,192C672,171,768,85,864,64C960,43,1056,85,1152,90.7C1248,96,1344,64,1392,48L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"  data-darkreader-inline-fill=""></path></svg>


			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#e2243b" fill-opacity="1" d="M0,256L40,240C80,224,160,192,240,181.3C320,171,400,181,480,192C560,203,640,213,720,208C800,203,880,181,960,149.3C1040,117,1120,75,1200,96C1280,117,1360,203,1400,245.3L1440,288L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"  data-darkreader-inline-fill=""></path></svg>
		</div>
	)
}



export default Background
