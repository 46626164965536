import {useContext, useEffect, useMemo, useReducer, useState} from "react"
import MenuCat from "./MenuCat"
import './Menu.css'

import { useGet } from '../../Hooks'
import {GET_MENU_URL} from "../../Costants"
import {OrderContext} from "../../context/OrderContext"
import Loader from "../loader/Loader"





const menuReducer = ( oldState, action ) => {

	switch( action.type ){

		case 'OPEN_MENU':
			return {
				categoria:action.categoria
			}
		case 'CLOSE_MENU':
			return {
				categoria:null
			}

		default:
			break
	}
}


const initailMenuState ={
	categoria:null,
}


const MenuList = () => {

	const [ {params, }, ] = useContext(OrderContext)


	const [ getData, setGetData ] = useState({ url:null})
	const gState = useGet( getData )

	const [ menuState, menuDispatch ] = useReducer(menuReducer, initailMenuState )
	const [ menuData, setMenuData ] = useState([])


	const listItems = useMemo(() => {
		return menuData.map(( [cat, articoli], idx) => {
			let color = 'green'
			if ( idx % 2 === 0){
				color = 'red'
			}
			return( 
				<MenuCat
					categoria={cat}
					articoli={ articoli }
					color={color}
					key={ idx}
					idx={ idx }
					menuState={menuState}
					menuDispatch={ ( action) => menuDispatch( action) }
				/>
			)
		})
	}, [menuData, menuState, menuDispatch])


	useEffect( () => {


		if ( params?.db == null ) return 

		const url = new URL( GET_MENU_URL )
		const sParams = new URLSearchParams({db:params.db, all:false})
		url.search = sParams.toString()
		setTimeout( () => {
			setGetData({ url:url })

		}, 500)
	},[ params?.db ])
	


	useEffect( () => {
		if( gState?.data?.status === 'ok'){
			setMenuData(gState.data.data)
			setGetData({url:null})
		}
	}, [gState?.data?.status ])


	return(
		<section className='cat-list'>
			{
				gState?.loading === true ?
					<div
						style={{
							height:'100%',
							widows:'100%',
							display:'grid',
							placeItems:'center',
						}}
					>
					<Loader/>
					</div>:
					listItems
			}
		</section>
	)
}


export default MenuList
