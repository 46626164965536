import './Radio.css'

import { BsCashStack } from 'react-icons/bs'
import { FaCreditCard } from 'react-icons/fa'


const IconRadio = ({ setValueByEvent, selected }) => {









	return (

		<div class="radio-inputs">
		<label>
			<input 
				onChange={ event => {
					const trg = event.target
					if ( trg.checked === true ){
						setValueByEvent( event)
					}
				}}
				value='contanti'
				class="radio-input" 
				type="radio" 
				checked={ selected === 'contanti'}
				name="pagamento"
			/>
				<span class="radio-tile">
					<span class="radio-icon">
						<BsCashStack/>
					</span>
					<span class="radio-label">Contanti</span>
				</span>
		</label>

		<label>
			<input 
				onChange={ event => {
					const trg = event.target
					if ( trg.checked === true ){
						setValueByEvent( event)
					}
				}}
				value='pos'
				class="radio-input" 
				type="radio" 
				name="pagamento"
				checked={ selected === 'pos'}
			/>
			<span class="radio-tile">
				<span class="radio-icon">
					<FaCreditCard/>
				</span>
				<span class="radio-label">POS</span>
			</span>
		</label>
		</div>
	)
}



export default IconRadio
