import {useMemo} from "react"
import ArticoloItem from "./ArticoloItem"



const ArticoliList = ({ articoli }) => {


	const articoliList = useMemo(() =>{


		return articoli.map( (art, idx) => {

			return (
				<ArticoloItem
					articolo={ art }
					key={idx}
				/>
			)
		})



	}, [articoli] )

	return(
		<ul>

			{ articoliList }


		</ul>
	)
}

export default ArticoliList
