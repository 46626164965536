import Card from "../views/wrapper/Card"
import '../views/gestione_menu/GestioneMenu.css'
import {useEffect, useReducer, useState} from "react"
import {useGet} from "../Hooks"
import {GET_MENU_URL} from "../Costants"
import SelectDb from '../views/select/SelectDb'
import useSessionStorageState from "use-session-storage-state"
import CreateCategoryModal from "../views/gestione_menu/CreateCategoryModal"
import Button from "../views/button/Button"
import MenuSection from "../views/gestione_menu/MenuSection"
import Loader from '../views/loader/Loader'
import {useOutletContext} from "react-router-dom"
import CreateArticoloModal from "../views/gestione_menu/CreateArticoloModal"
import DeleteItemModal from "../views/gestione_menu/DeleteItemModal"








const modalReducer = ( oldState, action ) => {

	switch( action.type ){
		case 'CREATE_CATEGORIA':
			return{
				...initialModalValues,
				create_categoria:true
			}

		case 'EDIT_ARTICOLO':
			return{
				...initialModalValues,
				create_articolo:true,
				articolo:action.articolo,
			}
		case 'EDIT_CATEGORIA':
			return{
				...initialModalValues,
				create_categoria:true,
				categoria:action.categoria,
			}

		case 'CREATE_ARTICOLO':
			return{
				...initialModalValues,
				create_articolo:true
			}
		case 'DELETE_ITEM':
			return{
				...initialModalValues,
				delete_item:true,
				seletedItem:action.item
			}

		case 'HIDE':
			return initialModalValues


		default:
			break
	}


}


const initialModalValues ={
	create_categoria:false,
	create_articolo:false,
	articolo:null,
}


const GestioneMenuPage = () => {


	const [menuCoontext, setMenuContext ] = useOutletContext()


	const [ user, ] = useSessionStorageState( 'user')



	const [ db, setDb ] = useState( menuCoontext?.params?.selectedDb ?? 1)
	const [ modalState, modalDispatch ] = useReducer(modalReducer, initialModalValues )

	const [ getData, setGetData ] = useState({url:null})
	const gState = useGet(getData)


	useEffect( () => {
		const url = new URL ( GET_MENU_URL )
		const params = new URLSearchParams({
			db:db,
			all:true,
		})
		url.search = params.toString()
		setGetData({ url:url })

		setMenuContext( old => {
			return{
				...old,
				db:db
			}
		})

	}, [
		db, 
		// modalState 
	])


	useEffect( () => {
		if ( gState?.data?.status === 'ok'){
			// setMenuData( gState.data.data )
			setMenuContext( old => {
				return {
					...old,
					menuModalDispatch:modalDispatch,
					menu_data:gState.data.data,
				}
			})
			setGetData({url:null})
		}

	}, [
		gState?.data?.status,
		setMenuContext
	])






	return (
		<main className="page gestione-menu">

			{
				modalState.delete_item === true &&
				<DeleteItemModal
					db={db}
					showHadler={ () => modalDispatch({type:'HIDE'}) }
					item={modalState.seletedItem }
				/>

			}

			{
				modalState.create_articolo === true &&
				<CreateArticoloModal
					db={db}
					showHadler={ () => modalDispatch({type:'HIDE'}) }
					articoloEdit={ modalState.articolo }
				/>

			}

			{
				modalState.create_categoria === true &&
				<CreateCategoryModal
					db={db}
					showHadler={ () => modalDispatch({type:'HIDE'}) }
					categoria={ modalState.categoria  }
				/>

			}

			<SelectDb
				setValue={setDb}
				value={db}

			/>



			{
				gState.loading === true ?
					<Loader/>:
					<MenuSection
						// menu_data={ menuCoontext.menu_data ?? []}
					/>
			}


			<Button
				text="NUOVA CATEGORIA"
				onClick={ () => modalDispatch({type:"CREATE_CATEGORIA"})}
			/>


		</main>
	)

}


export default GestioneMenuPage
