import {useContext, useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {OrderContext} from "../../context/OrderContext"
import {DATABASE_URL} from "../../Costants"
import {useGet} from "../../Hooks"
import Button from "./Button"





const NavButton = ({ top }) => {



	const [ orderContext, setOrderContext ] = useContext( OrderContext )
	const [ getData, setGetData ] = useState({ url:null })
	const gState = useGet( getData )


	useEffect( () => {

		const db = orderContext.params.db 

		if ( db == null ) return 

		const url = new URL( `${db}/`, DATABASE_URL )

		setTimeout(() => {
			setGetData({ url:url})
		}, 500)
	}, [ orderContext?.params?.db ])



	useEffect( () => {
		if ( gState?.data?.status  === 'ok'){
			setOrderContext( old => {
				return{
					...old,
					params:{
						...old.params,
						is_active:gState.data.data.is_active
					}
				}	 
			})
			setGetData({url:null})
		}


	}, [gState?.data?.status ])

	const [ orderArticoli, ] = useContext(OrderContext)
	
	const location = useLocation()
	const navigate = useNavigate()



	const clickAction = () => {
		if ( location.pathname === '/payment'){
			navigate(`/menu/${orderArticoli.params.slug}`)
		}
		else{
			navigate('/payment')
		}
	}


	const btnText = () => {

		if ( location.pathname === '/payment'){
			return "MENU'"
		}
		else  {
			return 'ORDINA'
		}

	}

	const position = () => {
		if ( top === true ){
			return {
				"top":"0"
			}
		}
		else {
			return {
				"bottom":"0"
			}
		}
	}


	return(
		<div 
			className='nav-button-wrap'
			style={position()}
		>

			{
				orderContext.params.is_active === true ?
					<>
					<Button
						text={ btnText() }
						onClick={ clickAction }
					/>
					<label>{Number(orderArticoli.totale).toFixed(2)} €</label>
					</>:

					<h2>Al momento non accettiamo ordini, grazie.</h2>
			}

		</div>
	)
}


export default NavButton
