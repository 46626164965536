import {useEffect, useReducer, useState} from "react"
import {act} from "react-dom/test-utils"
import {toast} from "react-hot-toast"
import {useOutletContext} from "react-router-dom"
import useSessionStorageState from "use-session-storage-state"
import {ORDINI_URL} from "../Costants"
import {useGet, useWebSocket} from "../Hooks"
import ActivateButton from "../views/orders/ActiveteButton"
import OrderDetails from "../views/orders/OrderDetails"
import OrdersList from "../views/orders/OrdersList"
import SelectDb from "../views/select/SelectDb"



const ordersReducer = ( oldState, action ) => {
	switch( action.type ){
		case 'SELECT':
			return {
				...oldState,
				selected:action.order,
			}

		case 'CLOSE':
			return {
				...initialReducerValues
			}
			
		default:
			break
	}
}





const initialReducerValues = {
	selected:null,
}


const OrdersPage = () => {

	const [ user, ] = useSessionStorageState('user', )

	const [ getData, setGetData ] = useState({url:null})
	const gState = useGet( getData )


	const [ { ordini, params, }, setOrdiniContext  ] = useOutletContext()
	const [ wsData, setWsData ] = useWebSocket()
	const [ ordersState, ordersDispatch ] = useReducer(ordersReducer, initialReducerValues )
	const [ db, setDb ] = useState(params?.selectedDb ?? 1)


	useEffect(() => {
		if ( wsData?.action === 'update' && wsData?.data != null ){
			if ( Number( db ) === Number(wsData?.data[0].database_id ) ){
				setOrdiniContext( old => {
					let newOrdini = [ ...old.ordini ]
					newOrdini.push( wsData.data )
					toast.success("Nuovo ordine!")
					return {
						...old,
						ordini:newOrdini
					}
				})

			}
			setWsData( null )
		}
	}, [ wsData, wsData?.action, wsData?.data, db])


	useEffect( () => {
		const url = new URL( `${db}/`, ORDINI_URL )
		setGetData({ url:url })
	}, [db])


	useEffect( () => {
		if ( gState?.data?.status === 'ok'){
			setOrdiniContext( old => {
				return {
					...old,
					ordini:gState?.data?.data
				}
			})

		setGetData({ url:null })
		}
	}, [gState?.data?.status ])





	return (
		<main className='page orders-page'>



			<div>

			<ActivateButton
				db={db}
			/>

			<SelectDb
				name="db"
				setValue={setDb}
				value={db}
				placeholder="seleziona il database"
			/>
			</div>

			<div className='orders'>

			<OrdersList
				ordersDispatch={ action => ordersDispatch( action )}
			/>

			<OrderDetails
				ordersDispatch={ action => ordersDispatch( action )}
				ordersState={ ordersState }
			/>
			</div>
		</main>
	)
}


export default OrdersPage
