import Button from "../button/Button"
import Modal from "../wrapper/Modal"
import '../wrapper/Wrappers.css'

import { BsFillCheckCircleFill } from 'react-icons/bs'


const ConfermaOrdineModal = ({closeModal, errors}) => {


	return (
		<Modal
			showHadler={ closeModal }
		>
			<div 
				className='dialog'
				style={{ paddingTop:'20px'}}
			>


				<i>
				<div/>
				<BsFillCheckCircleFill/>
				</i>


				<h2>Ordine inviato!</h2>

				<p>Il tuo ordine è stato inviato con successo.
				<br/>
				Grazie e buon divertimento!
				<br/>
				</p>

				<Button
					text='Ok'
					onClick={ closeModal }
				/>
			</div>
		</Modal>

	)
}


export default ConfermaOrdineModal 
