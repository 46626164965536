
const genBaseUrl = () => {
	if ( window.location.port === '3000'){
		// return new URL('http://192.168.178.48:8000/api/')
		// return new URL('http://starmenu.it:8000/api/')
		return new URL('http://dev.starmenu.it:8000/api/')
		// return new URL('http://192.168.1.111:8000/api/')
		//return new URL('http://localhost:8000/api/')
	}
	else{
		return new URL( `${window.location.origin}/api/`)
	}
	
}

const genBaseWsUrl = () => {

	const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
	if ( window.location.port === '3000'){
		// return new URL('ws://localhost:8000/')
		 return new URL(`${protocol}//dev.starmenu.it:8000/ws/`)
	}
	else{
		return new URL( `${protocol}//${window.location.hostname}:${window.location.port}/ws/`)
	}
}


const BASE_URL = genBaseUrl()
export const BASE_WS = genBaseWsUrl()



export const STATIC_URL = new URL( 'assets/', BASE_URL.href.replace("api/", "") )

export const WS_URL = new URL( 'ws/', BASE_WS )
export const PERSONALE_URL = new URL( 'personale/', BASE_URL )
export const SELECT_URL = new URL( 'select/', BASE_URL )
export const TAVOLI_URL = new URL( 'tavoli/', BASE_URL )
export const ARTICOLI_URL = new URL( 'articoli/',  BASE_URL )
export const PRINT_URL = new URL( 'print/',  BASE_URL )
export const COMANDA_URL = new URL( 'comanda/',  BASE_URL )


export const GET_USERS_URL = new URL( 'account/',  BASE_URL )
export const CREATE_USER_URL = new URL( 'account/create/',  BASE_URL )
export const EDIT_USER_URL = new URL( 'account/edit/',  BASE_URL )
export const LOGIN_URL = new URL( 'account/login/',  BASE_URL )
export const LOGOUT_URL = new URL( 'account/logout/',  BASE_URL )
export const REFRESH_URL = new URL( 'account/refresh/',  BASE_URL )
export const GET_MENU_URL = new URL( 'menu/',  BASE_URL )
export const GET_DB_URL = new URL( 'account/db/',  BASE_URL )
export const CREATE_CATEGORY_URL = new URL( 'category/create/',  BASE_URL )
export const EDIT_CATEGORY_URL = new URL( 'category/edit/',  BASE_URL )
export const CREATE_ARTICOLO_URL = new URL( 'articoli/create/',  BASE_URL )
export const EDIT_ARTICOLO_URL = new URL( 'articoli/edit/',  BASE_URL )
export const GET_ARTICOLI_URL = new URL( 'articoli/',  BASE_URL )
export const SET_ARTICOLO_DISPONIBILE_URL = new URL( 'articoli/disponibile/',  BASE_URL )
export const DELETE_ITEM_URL = new URL( 'menu/',  BASE_URL )


export const ORDINI_URL = new URL( 'ordine/',  BASE_URL )
export const CONSEGNA_ORDINE_URL = new URL( 'ordine/consegna/',  BASE_URL )
export const DATABASE_URL = new URL( 'ordine/active/',  BASE_URL )



