import Button from "../button/Button"
import Modal from "./Modal"
import './Wrappers.css'

import { BsFillCheckCircleFill } from 'react-icons/bs'


const AllergeniModal = ({closeModal, errors}) => {


	return (
		<Modal
			showHadler={ closeModal }
		>
			<div 
				className='dialog'
				style={{ paddingTop:'20px'}}
			>


				<i>
				<div/>
				<BsFillCheckCircleFill/>
				</i>


				<h2>Allergeni</h2>

				<p>Tutti i piatti possono contenere tracce dei seguenti allergeni:
				glutine, crostacei, uova, pesce, arachidi, semi di soia, latte, 
				frutta a guscio (ad es. mandorle, nocciole, noci, anacardi, noci 
				pecan, noci brasiliane, pistacchi, noci di macadamia), sedano, senape, 
				semi di semano, diossido di zolfo / solfiti, lupino, molluschi.
				<br/>
				<br/>
				Per qualsiasi domanda sugli allergeni contenuti in piatti specifici 
				chiedere direttamente al personale del il ristorante
				</p>

				<Button
					text='Ok'
					onClick={ closeModal }
				/>
			</div>
		</Modal>

	)
}


export default AllergeniModal 
