
import './Accounts.css'




const AccountList = ({ accounts }) => {


	return(

		<section className='account-list'>

		
			{accounts}

		</section>
	)
}


export default AccountList
