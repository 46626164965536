import {  useMemo,  useRef,  useState } from 'react'
import './Input.css'


const TextArea = ({  
	id,
	label,
	value,
	setValueById,
	setValueByEvent,
	disabled=false,
	error,
	onblur=null,
	onfocus=null,
	name,
}) => {

	const wrapRef = useRef()
	const inputRef = useRef()
	const labelRef = useRef()
	const [ errors, setErrors ] = useState([])
	const errorLabel = useMemo( () => {

		if (
			error != null
		){
			return error
		}
		else if (
			( errors && errors[id] != null ) ||  
			(  error != null )    
		){
			return <label className='error-label'>{
				error != null ?
					error[id]:
					errors[id]
			}</label>
		}
		return null
	}, [ errors, error ] )


	return (

		<div 
			className='text-area-wrap'
			ref={ wrapRef }
		>

			<label 
				className='text-area-label' 
				ref={ labelRef }
				onClick={ () =>{
					inputRef.current.focus()
				}}
				htmlFor={ id }
			>
				{label}
			</label>


			<textarea
				id={ id }
				className='text-area'
				name={ name }
				ref={ inputRef	}
				disabled={ disabled }
				value={ value }

				onKeyDown={ event => {
					if( event.keyCode === 13 ){
						event.stopPropagation()
						event.preventDefault()
					}
				}}
				onKeyUp={ event => {
					event.stopPropagation()
					event.preventDefault()
					if( event.keyCode === 13 ){
					}
				}}

				onFocus={ event => {
					onfocus &&
						onfocus(event)
				}}


				onBlur={ event => {
					onblur &&
						onblur( event )
				}}


				onChange={ event => {
					setValueByEvent( event )
				} }
			/>

			{
				errorLabel

			}



		</div>
	)
}


export default TextArea
