
import {useEffect, useMemo, useRef} from "react"
import Card from "../wrapper/Card"
import MenuArticolo from "./MenuArticolo"
import { GrAddCircle } from 'react-icons/gr'
import Button from "../button/Button"
import {useOutletContext} from "react-router-dom"

import { IoTrashOutline } from 'react-icons/io5'
import { CiEdit } from 'react-icons/ci'
import { ImBlocked } from 'react-icons/im'



const MenuCat = ({ cat, articoli, catActive, setCatActive }) => {


	const articoliRef = useRef()

	const [ {menuModalDispatch, }, setMenuContext ] = useOutletContext()

	const render_art = useMemo(() => {

		return articoli.map( (art, idx) => {
			return (
				<MenuArticolo
					articolo={art}
					key={`${art.id}_${idx}`}
				/>
			)
		})
	}, [articoli, articoli.length ])


	useEffect(() => {
		if ( catActive === cat.id ){
			const height = (82 +10) * articoli.length 
			articoliRef.current.style.height = `${height + 20 }px`
		}
		else{
			articoliRef.current.style.height = `0px`

		}
	}, [catActive, articoli.length, cat.id ])


	return ( 
		<Card style={{
			padding:"7px 5px",
			border:'3px ridge var(--green)'

			}}>
			<div 
				className='menu-cat'
				onClick={ event => {
					event.stopPropagation()
					if ( articoli.length > 0){
						setCatActive()
					}
				}}
			>

				<div>

					<span>

					<i
						onClick={event=>{

							event.stopPropagation()
							menuModalDispatch({type:'EDIT_CATEGORIA', categoria:cat})
						}}
					>
						<CiEdit/>
					</i>
					
					<i
						onClick={event => {
							event.stopPropagation()
							menuModalDispatch({type:'DELETE_ITEM', item:cat})
						}}
					>
						<IoTrashOutline/>
					</i>
					</span>
				<label
					style={{ 
						justifySelf:'flex-start',
						textAlign:'left',
						overflowWrap: 'anywhere',
					}}
				>{cat.nome}</label>
				<Button
					text="Nuovo Articolo"
					icon={(<GrAddCircle/>)}
					onClick={() => {
						setMenuContext( old => {
							return {
								...old,
								articolo:{
									categoria_id:cat.id
								}
							}
						})
						menuModalDispatch({type:"CREATE_ARTICOLO"})
					}}
				/>

					{
						cat.is_disponibile !== true &&
							<i>
								<ImBlocked/>
							</i>
					}
				</div>

				<div
					className='articoli-wrap'
					ref={ articoliRef }
				>
					{render_art}
				</div>

			</div>
		</Card>
	)
}


export default MenuCat
